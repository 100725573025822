import React from 'react';
import underconstruction from '../styles/assets/underconstruction.jpg';

export const UnderConstruction = () => {
    return (
        <div style={{ height: 'calc(100vh - 50px)' }}>
            <div
                style={{
                    backgroundImage: `url(${underconstruction})`,
                    backgroundPosition: 'center center',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    height: 'calc(100vh - 48px)',
                }}
            >
                <div />
            </div>
        </div>
    );
};
