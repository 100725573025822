import * as React from 'react';
import {
    Button,
    Card,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Modal,
    Select,
    SelectChangeEvent,
    ThemeProvider,
} from '@mui/material';
// import { MultipleChoice } from '../modules/assignments/MultipleChoice';
import {
    Module,
    ModulePage,
    Question,
    UserAnswers,
} from '../interfaces/Interfaces';
import { ModulePreviewCard } from '../modules/ModulePreviewCard';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
// import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import { MultipleChoice } from '../modules/assignments/MultipleChoice';
import { useExtensionsReadOnly } from '../utilities/RichTextEditor/useExtensions';
import { RichTextReadOnly } from 'mui-tiptap';
import { useGlobalStore } from '../utilities/GlobalState';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import {
    defaultTheme,
    formatDateWithTime2,
    getHeaderIcon,
} from '../utilities/Utilites';
import { Datagrid } from '../utilities/Datagrid';
import { DateTime } from 'luxon';
// import GradingIcon from '@mui/icons-material/Grading';
import WysiwygIcon from '@mui/icons-material/Wysiwyg';
import { AuthPost } from '../utilities/Routing/Requests';
import { toast } from 'react-toastify';
import { ModuleTableOfContents } from './ModuleTableOfContents';
import { ModulePreviewDetails } from '../classes/ModulePreviewDetails';
import { DatePicker } from '@mui/x-date-pickers';

interface Props {
    questions: Array<Question>;
    modules: Array<Module>;
    pages: Array<ModulePage>;
    currentPage: number;
    setCurrentPage: (page: number) => void;
    getModulePages: (id: number) => Promise<Array<ModulePage>>;
    setPages: (pages: Array<ModulePage>) => void;
    disableForward: boolean;
    setDisableForward: (disable: boolean) => void;
    classID: number | undefined;
    instructorID?: number | undefined;
    toggleBack?: () => void;
    setViewModuleDetailsParent?: (module: number) => void;
    showGradebook?: (studentID?: number, moduleID?: number) => void;
    cardView?: boolean;
    selectedModule?: number;
    classTitle?: string;
    overViewsOnly?: boolean;
}

const AssignmentsTab = ({
    modules,
    pages,
    currentPage,
    setCurrentPage,
    getModulePages,
    setPages,
    disableForward,
    setDisableForward,
    classID,
    instructorID,
    toggleBack,
    setViewModuleDetailsParent,
    showGradebook,
    cardView,
    selectedModule,
    classTitle,
    overViewsOnly,
}: Props) => {
    const [selectedClass, setSelectedClass] = React.useState<number>(0);
    const [viewModuleDetails, setViewModuleDetails] = React.useState<number>(0);
    const [showModuleDetails, setShowModuleDetails] = React.useState<any>();
    const [instructorClasses, setInstructorClasses] =
        React.useState<Array<Module>>();
    const [startDate, setStartDate] = React.useState<any>(DateTime.now());
    const [endDate, setEndDate] = React.useState<any>(DateTime.now());
    const [showSubmitButton, setShowSubmitButton] =
        React.useState<boolean>(false);
    const [questions, setQuestions] = React.useState<Array<Question>>();
    const [answers, setAnswers] = React.useState<Array<UserAnswers | null>>([]);
    const globalState = useGlobalStore((state) => state);
    const extensions = useExtensionsReadOnly({
        placeholder: 'Add your own content here...',
    });

    const moduleTitle = () => {
        return modules?.filter((module) => module?.id === viewModuleDetails)[0]
            ?.title;
    };

    const getPageByNumber = (pageNumber: number): ModulePage => {
        // return pages?.filter((page) => {
        //     return Number(page.pageNumber) === pageNumber + 1;
        // })[0];
        const filteredPage = pages.filter((page) => {
            return Number(page.pageNumber) === pageNumber + 1;
        })[0];
        return {
            ...filteredPage,
            data: filteredPage?.data?.replaceAll(
                'class="column">',
                'class="column-readonly">',
            ),
        };
    };

    const toggleShowGradebook = (studentID?: number, moduleID?: number) => {
        if (showGradebook) {
            showGradebook(studentID, moduleID);
        }
    };

    React.useEffect(() => {
        if (getPageByNumber(currentPage)?.data?.startsWith('{"questions":')) {
            const quests: any = {
                ...JSON.parse(getPageByNumber(currentPage).data || ''),
            };
            setQuestions(quests?.questions);
        } else {
            setDisableForward(false);
        }
    }, [currentPage]);

    const checkModuleProgress = (moduleID: number, modulePageID: number) => {
        const progress = AuthPost(
            '/progress/getuserprogress',
            {
                classID: classID,
                userID: globalState?.user?.id,
                moduleID: moduleID,
                modulePageID: modulePageID,
            },
            {
                'x-access-token': globalState?.authToken || '',
                canCache: true,
            },
        )
            .then((res) => {
                if (res?.status === 200 || res?.status === 204) {
                    return res.data;
                } else {
                    return [];
                }
            })
            .catch((err) => {
                console.error(err.toString());
                toast.error(err.toString());
            });
        return progress.then((res) => {
            return res.recordset;
        });
    };

    // make explicit with button
    React.useEffect(() => {
        const isAssessment =
            getPageByNumber(currentPage)?.data?.startsWith('{"questions":') &&
            questions?.length;
        checkModuleProgress(
            Number(pages?.[currentPage]?.moduleId),
            pages?.[currentPage]?.id,
        ).then((progress) => {
            const completed = progress?.every(
                (obj: any) => obj?.status === 'complete',
            );
            if (
                currentPage + 1 === pages?.length &&
                !isAssessment &&
                !completed
            ) {
                setShowSubmitButton(true);
            }
        });
    }, [currentPage]);
    const completeModule = () => {
        AuthPost(
            '/progress/updateprogress',
            {
                classID: classID,
                userID: globalState?.user?.id,
                moduleID: Number(pages?.[currentPage]?.moduleId),
                instructorID: instructorID,
                status: 'complete',
            },
            {
                'x-access-token': globalState?.authToken || '',
                canCache: true,
            },
        )
            .then((res) => {
                if (res?.status === 200 || res?.status === 204) {
                    if (setViewModuleDetailsParent) {
                        setViewModuleDetailsParent(0);
                        setViewModuleDetails(0);
                    }
                    return res.data;
                } else {
                    if (setViewModuleDetailsParent) {
                        setViewModuleDetailsParent(0);
                        setViewModuleDetails(0);
                    }
                    return [];
                }
            })
            .catch((err) => {
                console.error(err.toString());
                toast.error(err.toString());
            });
    };

    const setModuleProgressStatus = (
        moduleID: number,
        modulePageID: number,
        status: string,
    ) => {
        return AuthPost(
            '/progress/addprogress',
            {
                classID: classID,
                userID: globalState?.user?.id,
                moduleID: moduleID,
                modulePageID: modulePageID,
                status: status,
            },
            {
                'x-access-token': globalState?.authToken || '',
                canCache: true,
            },
        )
            .then((res) => {
                if (res?.status === 200 || res?.status === 204) {
                    return res.data;
                } else {
                    return [];
                }
            })
            .catch((err) => {
                console.error(err.toString());
                toast.error(err.toString());
            });
    };

    const handleToggleModulePage = (modID: number, firstRun?: boolean) => {
        if (setViewModuleDetailsParent && !firstRun) {
            setViewModuleDetailsParent(modID);
        }
        setViewModuleDetails(modID);
        globalState.setCurrentClass(classID);
        getModulePages(modID).then((res) => {
            const progress = () =>
                checkModuleProgress(modID, res?.[currentPage]?.id);
            progress().then((res1) => {
                if (!res1?.length) {
                    setModuleProgressStatus(
                        modID,
                        res?.[currentPage]?.id,
                        'started',
                    );
                }
            });
            setPages(
                res?.sort(
                    (a: any, b: any) =>
                        Number(a.pageNumber) - Number(b.pageNumber),
                ),
            );
        });
        setCurrentPage(0);
    };

    const getInstructorClasses = () => {
        AuthPost(
            '/class/getinstructorclasses',
            {
                instructorID: globalState?.user?.id,
            },
            {
                'x-access-token': globalState?.authToken || '',
                canCache: true,
            },
        )
            .then((res) => {
                if (res?.status === 200 || res?.status === 204) {
                    setInstructorClasses(res?.data);
                    return res?.data;
                } else {
                    return [];
                }
            })
            .catch((err) => {
                console.error(err.toString());
                toast.error(err.toString());
            });
    };

    React.useEffect(() => {
        if (selectedModule) {
            handleToggleModulePage(selectedModule, true);
        }
        if (
            !globalState?.user?.type?.includes('Admin') &&
            globalState?.user?.type?.includes('Instructor') &&
            overViewsOnly
        ) {
            getInstructorClasses();
        }
    }, []);

    const handleSubmit = (
        event: React.FormEvent<HTMLFormElement>,
        moduleID?: number,
    ) => {
        event.preventDefault();

        AuthPost(
            '/classdetail/addclassdetail',
            {
                moduleID: moduleID,
                classID: selectedClass,
                startDate: DateTime.fromJSDate(new Date(startDate)).toString(),
                endDate: DateTime.fromJSDate(new Date(endDate)).toString(),
            },
            {
                'x-access-token': globalState.authToken,
            },
        )
            .then((res) => {
                if (res?.status === 200) {
                    toast.success('Class Successfully Updated!');
                }
            })
            .catch((err) => {
                toast.error(err.toString());
            });
    };

    const handleClassChange = (event: SelectChangeEvent) => {
        setSelectedClass(Number(event.target.value));
    };

    return (
        <ThemeProvider theme={defaultTheme}>
            <Modal
                open={showModuleDetails?.id ? true : false}
                onClose={() => setShowModuleDetails(undefined)}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    outline: 'none',
                }}
            >
                <Card
                    sx={{
                        width: '90%',
                        margin: '10px',
                        outline: 'none',
                        overflow: 'auto',
                    }}
                >
                    <>
                        {showModuleDetails ? (
                            <ModulePreviewDetails
                                module={showModuleDetails}
                                toggleModuleDetails={() => {
                                    setShowModuleDetails(undefined);
                                }}
                                addModuleButton={(modID) => {
                                    return (
                                        <>
                                            <Grid
                                                container
                                                sx={{
                                                    padding: '10px',
                                                    borderRadius: '20px',
                                                    boxShadow:
                                                        'rgb(0, 0, 0) 0px 2px 11px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px',
                                                }}
                                            >
                                                <Grid
                                                    xs={3}
                                                    sx={{
                                                        textAlignLast: 'center',
                                                        margin: 'auto',
                                                    }}
                                                >
                                                    <DatePicker
                                                        label="Start Date"
                                                        value={
                                                            startDate ||
                                                            DateTime.now()
                                                        }
                                                        sx={{ width: '100%' }}
                                                        onChange={(
                                                            newValue: any,
                                                        ) => {
                                                            setStartDate(
                                                                newValue,
                                                            );
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid
                                                    xs={3}
                                                    sx={{
                                                        textAlignLast: 'center',
                                                        margin: 'auto',
                                                    }}
                                                >
                                                    <DatePicker
                                                        label="End Date"
                                                        value={
                                                            endDate ||
                                                            DateTime.now()
                                                        }
                                                        onChange={(
                                                            newValue: any,
                                                        ) => {
                                                            setEndDate(
                                                                newValue,
                                                            );
                                                        }}
                                                        sx={{ width: '100%' }}
                                                    />
                                                </Grid>
                                                <Grid
                                                    xs={4}
                                                    sx={{
                                                        textAlignLast: 'center',
                                                        margin: 'auto',
                                                    }}
                                                >
                                                    <FormControl fullWidth>
                                                        <InputLabel
                                                            id={'select-class'}
                                                        >
                                                            Class
                                                        </InputLabel>
                                                        <Select
                                                            labelId="select-class"
                                                            id="select-class-menu"
                                                            value={selectedClass?.toString()}
                                                            label="Class"
                                                            onChange={
                                                                handleClassChange
                                                            }
                                                            sx={{
                                                                width: '100%',
                                                            }}
                                                        >
                                                            {instructorClasses?.map(
                                                                (
                                                                    instructorClass: any,
                                                                ) => {
                                                                    return (
                                                                        <MenuItem
                                                                            key={
                                                                                instructorClass?.classID
                                                                            }
                                                                            value={
                                                                                instructorClass?.classID
                                                                            }
                                                                        >
                                                                            {
                                                                                instructorClass?.className
                                                                            }
                                                                        </MenuItem>
                                                                    );
                                                                },
                                                            )}
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                                <Grid
                                                    xs={2}
                                                    sx={{
                                                        textAlignLast: 'center',
                                                        margin: 'auto',
                                                    }}
                                                >
                                                    <Button
                                                        variant="contained"
                                                        sx={{ margin: '16px' }}
                                                        onClick={(e: any) => {
                                                            handleSubmit(
                                                                e,
                                                                modID,
                                                            );
                                                            setShowModuleDetails(
                                                                undefined,
                                                            );
                                                        }}
                                                    >
                                                        ADD MODULE
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </>
                                    );
                                }}
                            />
                        ) : (
                            <></>
                        )}
                    </>
                </Card>
            </Modal>
            <Grid container>
                {viewModuleDetails > 0 && getPageByNumber(currentPage) ? (
                    <Grid container>
                        <IconButton
                            onClick={() => {
                                if (currentPage > 0) {
                                    setCurrentPage(currentPage - 1);
                                } else if (currentPage === 0 && toggleBack) {
                                    toggleBack();
                                    setViewModuleDetails(0);
                                } else {
                                    setCurrentPage(0);
                                }
                            }}
                            sx={{
                                backgroundColor: 'rgba(0, 179, 239,0.7)',
                                position: 'fixed',
                                top: 'calc(50% + 80px)',
                                left: '30px',
                                transform: 'translate(-50%, -50%)',
                                display: 'flex',
                                gap: '10px',
                                zIndex: '1000',
                                ':hover': {
                                    backgroundColor: 'rgba(0, 179, 239, 1)',
                                },
                            }}
                        >
                            <ArrowBackIcon />
                        </IconButton>
                        <IconButton
                            onClick={() => {
                                if (currentPage + 1 < pages?.length) {
                                    setCurrentPage(currentPage + 1);
                                }
                            }}
                            disabled={disableForward}
                            sx={{
                                backgroundColor: 'rgba(0, 179, 239,0.7)',
                                position: 'fixed',
                                top: 'calc(50% + 80px)',
                                right: '-10px',
                                transform: 'translate(-50%, -50%)',
                                display: 'flex',
                                gap: '10px',
                                zIndex: '1000',
                                ':hover': {
                                    backgroundColor: 'rgba(0, 179, 239, 1)',
                                },
                            }}
                        >
                            <ArrowForwardIcon />
                        </IconButton>
                        <Grid
                            xs={2}
                            sx={{
                                textAlignLast: 'center',
                                boxShadow:
                                    '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)',
                            }}
                        >
                            <div className="Class-Title">{classTitle}</div>
                        </Grid>
                        <Grid xs={1}>
                            {getHeaderIcon(pages?.[currentPage]?.headerIcon)}
                        </Grid>
                        <Grid
                            sx={{
                                textAlignLast: 'left',
                            }}
                            xs={9}
                        >
                            <div className="Module-Title">{moduleTitle()}</div>
                        </Grid>
                        <Grid
                            xs={2}
                            sx={{
                                textAlignLast: 'left',
                            }}
                        >
                            <ModuleTableOfContents
                                pages={pages}
                                currentPage={currentPage || 0}
                                setCurrentPage={setCurrentPage}
                                viewModuleDetails={viewModuleDetails || 0}
                            />
                        </Grid>
                        <Grid xs={10}>
                            <Card
                                style={{
                                    width: '100%',
                                    minHeight: 'calc(100vh - 170px)',
                                    boxShadow:
                                        '0px 2px 8px -1px rgba(0,0,0,4.8),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)',
                                    // clipPath: 'inset(-10px 0px -10px -10px)',
                                }}
                            >
                                {getPageByNumber(currentPage)?.data?.startsWith(
                                    '{"questions":',
                                ) && questions?.length ? (
                                    <MultipleChoice
                                        questions={questions}
                                        setAnswers={setAnswers}
                                        answers={answers}
                                        page={getPageByNumber(currentPage)}
                                        setDisableForward={setDisableForward}
                                        classID={classID}
                                        setViewModuleDetails={
                                            setViewModuleDetails
                                        }
                                        finalPage={
                                            currentPage + 1 === pages?.length
                                        }
                                        // gradebookID={}
                                    />
                                ) : (
                                    <div style={{ margin: '15px' }}>
                                        {showSubmitButton ? (
                                            <Button
                                                fullWidth
                                                variant="contained"
                                                sx={{ mt: 3, mb: 2 }}
                                                onClick={completeModule}
                                            >
                                                SUBMIT TO BE GRADED
                                            </Button>
                                        ) : (
                                            <></>
                                        )}
                                        <RichTextReadOnly
                                            content={getPageByNumber(
                                                currentPage,
                                            )?.data?.replaceAll(
                                                `class="column">`,
                                                `class="column-readonly">`,
                                            )}
                                            extensions={extensions}
                                        />
                                    </div>
                                )}
                            </Card>
                        </Grid>
                    </Grid>
                ) : (
                    <></>
                )}
                {viewModuleDetails < 1 &&
                    (cardView ? (
                        <>
                            <Card
                                onClick={toggleBack}
                                sx={{
                                    width: '300px',
                                    height: '300px',
                                    margin: '10px',
                                    textAlign: 'center',
                                    boxShadow:
                                        '0px 2px 11px -1px rgba(0,0,0,4.8),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)',
                                }}
                            >
                                <ArrowCircleLeftIcon
                                    key={'blog-grid-slider-left-icon'}
                                    sx={{
                                        paddingTop: '65px',
                                        width: '150px',
                                        height: '150px',
                                        color: `${defaultTheme.palette.primary.main}`,
                                    }}
                                />
                            </Card>
                            {modules.map((module) => {
                                return !overViewsOnly ? (
                                    <ModulePreviewCard
                                        key={module.id}
                                        handleToggleModulePage={() =>
                                            handleToggleModulePage(module.id)
                                        }
                                        module={module}
                                        showGradebook={() =>
                                            toggleShowGradebook(
                                                Number(globalState?.user?.id),
                                                module.id,
                                            )
                                        }
                                    />
                                ) : (
                                    <div
                                        key={module.id}
                                        onClick={() =>
                                            setShowModuleDetails(module)
                                        }
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            marginBottom: '20px',
                                        }}
                                    >
                                        <ModulePreviewCard
                                            key={module.id}
                                            handleToggleModulePage={() => {
                                                return;
                                            }}
                                            module={module}
                                        />
                                    </div>
                                );
                            })}
                        </>
                    ) : (
                        <>
                            {Datagrid({
                                sx: { height: 'unset' },
                                editMode: 'row',
                                columns: [
                                    {
                                        field: 'code',
                                        headerName: 'Code',
                                        type: 'string',
                                        flex: 0.25,
                                    },
                                    {
                                        field: 'title',
                                        headerName: 'Title',
                                        type: 'string',
                                        flex: 1,
                                    },
                                    {
                                        field: 'description',
                                        headerName: 'Description',
                                        type: 'string',
                                        flex: 1,
                                    },
                                    {
                                        field: 'active',
                                        headerName: 'Published',
                                        type: 'boolean',
                                        flex: 1,
                                    },
                                    {
                                        field: 'publishDate',
                                        headerName: 'Publish Date',
                                        type: 'string',
                                        flex: 1,
                                        renderCell: (rowData) => {
                                            const date = DateTime.fromISO(
                                                rowData.row.publishDate as any,
                                            ) as any;
                                            return rowData?.row?.publishDate
                                                ? formatDateWithTime2(date)
                                                : 'Unpublished';
                                        },
                                    },
                                    {
                                        field: 'action',
                                        headerName: 'Action',
                                        flex: 1,
                                        sortable: false,
                                        renderCell: (rowData: any) => {
                                            return (
                                                <>
                                                    <IconButton
                                                        sx={{
                                                            marginLeft: '5px',
                                                            backgroundColor:
                                                                defaultTheme
                                                                    .palette
                                                                    .primary
                                                                    .main,
                                                            border: 'solid #fff 1px',
                                                            color: '#fff',
                                                            fontWeight: 'bold',
                                                            fontSize: '20px',
                                                        }}
                                                        title="View"
                                                        onClick={() => {
                                                            if (
                                                                setViewModuleDetailsParent
                                                            ) {
                                                                setViewModuleDetailsParent(
                                                                    rowData?.row
                                                                        ?.id,
                                                                );
                                                            }
                                                            setViewModuleDetails(
                                                                rowData?.row
                                                                    ?.id,
                                                            );
                                                            globalState.setCurrentClass(
                                                                classID,
                                                            );
                                                            getModulePages(
                                                                rowData?.row
                                                                    ?.id,
                                                            ).then((res) =>
                                                                setPages(
                                                                    res?.sort(
                                                                        (
                                                                            a: any,
                                                                            b: any,
                                                                        ) =>
                                                                            Number(
                                                                                a.pageNumber,
                                                                            ) -
                                                                            Number(
                                                                                b.pageNumber,
                                                                            ),
                                                                    ),
                                                                ),
                                                            );
                                                            setCurrentPage(0);
                                                        }}
                                                    >
                                                        <WysiwygIcon />
                                                    </IconButton>
                                                </>
                                            );
                                        },
                                    },
                                ],
                                data: modules,
                            })}
                        </>
                    ))}
            </Grid>
        </ThemeProvider>
    );
};

export { AssignmentsTab };
