import * as React from 'react';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { styled, useTheme } from '@mui/material/styles';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import {
    Divider,
    Drawer,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    ThemeProvider,
} from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CottageIcon from '@mui/icons-material/Cottage';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import { useGlobalStore } from '../utilities/GlobalState';
import {
    defaultTheme,
    typeSelectOptionMapper,
    typeStringToSelectOptionFormatter,
    userTypes,
} from '../utilities/Utilites';
// import APT_Reseller_Portal from '../styles/assets/APT_Reseller_Portal.png';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import SchoolIcon from '@mui/icons-material/School';
import ClassIcon from '@mui/icons-material/Class';
import HubIcon from '@mui/icons-material/Hub';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import { OrganizationUser } from '../interfaces/Interfaces';
import customHistory from '../utilities/Routing/History';
import { MultiSelect } from '../utilities/MultiSelect';

export const HeaderNavBar = () => {
    const globalState = useGlobalStore((state) => state);
    const theme = useTheme();

    const [open, setOpen] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        globalState.setAuthenticated(false, '');
        globalState.clearState();
        useGlobalStore.persist.clearStorage();
        handleClose();
    };

    const handleAbout = () => {
        customHistory.push('/about');
        handleClose();
    };

    const handleProfile = () => {
        customHistory.push('/profile');
        handleClose();
    };

    const handleReport = () => {
        customHistory.push('/report');
        handleClose();
    };

    const drawerWidth = 240;

    const Main = styled('main', {
        shouldForwardProp: (prop) => prop !== 'open',
    })<{
        open?: boolean;
    }>(() => ({
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: `-${drawerWidth}px`,
        ...(open && {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: 0,
        }),
    }));

    interface AppBarProps extends MuiAppBarProps {
        open?: boolean;
    }

    const AppBar = styled(MuiAppBar, {
        shouldForwardProp: (prop) => prop !== 'open',
    })<AppBarProps>(() => ({
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        ...(open && {
            // width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: `${drawerWidth}px`,
            transition: theme.transitions.create(['margin', 'width'], {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
        }),
    }));

    const DrawerHeader = styled('div')(() => ({
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
    }));

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const sidebarNavItems = (
        <List>
            <ListItem key={'Landing Page'} disablePadding>
                <ListItemButton href="/">
                    <ListItemIcon>
                        <CottageIcon fontSize="large" />
                    </ListItemIcon>
                    <ListItemText primary={'Landing Page'} />
                </ListItemButton>
            </ListItem>
            {globalState?.user?.type?.includes('Admin') ? (
                <ListItem key={'Organizations'} disablePadding>
                    <ListItemButton href="/organizations">
                        <ListItemIcon>
                            <CorporateFareIcon fontSize="large" />
                        </ListItemIcon>
                        <ListItemText primary={'Organizations'} />
                    </ListItemButton>
                </ListItem>
            ) : (
                <></>
            )}
            {globalState?.user?.type?.includes('APTAdmin') ? (
                <ListItem key={'Distributors'} disablePadding>
                    <ListItemButton href="/distributors">
                        <ListItemIcon>
                            <HubIcon fontSize="large" />
                        </ListItemIcon>
                        <ListItemText primary={'Distributors'} />
                    </ListItemButton>
                </ListItem>
            ) : (
                <></>
            )}
            {globalState?.user?.type?.includes('Admin') ? (
                <ListItem key={'Instructors'} disablePadding>
                    <ListItemButton href="/instructors">
                        <ListItemIcon>
                            <LocalLibraryIcon fontSize="large" />
                        </ListItemIcon>
                        <ListItemText primary={'Instructors'} />
                    </ListItemButton>
                </ListItem>
            ) : (
                <></>
            )}
            <ListItem key={'Classes'} disablePadding>
                <ListItemButton href="/classes">
                    <ListItemIcon>
                        <Diversity3Icon fontSize="large" />
                    </ListItemIcon>
                    <ListItemText primary={'Classes'} />
                </ListItemButton>
            </ListItem>
            {globalState?.user?.type?.includes('Admin') ||
            globalState?.user?.type?.includes('Instructor') ? (
                <ListItem key={'Modules'} disablePadding>
                    <ListItemButton href="/modules">
                        <ListItemIcon>
                            <SchoolIcon fontSize="large" />
                        </ListItemIcon>
                        <ListItemText primary={'Modules'} />
                    </ListItemButton>
                </ListItem>
            ) : (
                <></>
            )}
            <ListItem key={'Certifications'} disablePadding>
                <ListItemButton href="/certifications">
                    <ListItemIcon>
                        <ClassIcon fontSize="large" />
                    </ListItemIcon>
                    <ListItemText primary={'Certifications'} />
                </ListItemButton>
            </ListItem>

            <ListItem key={'Equipment'} disablePadding>
                <ListItemButton href="/equipment">
                    <ListItemIcon>
                        <PrecisionManufacturingIcon fontSize="large" />
                    </ListItemIcon>
                    <ListItemText primary={'Equipment'} />
                </ListItemButton>
            </ListItem>
            {globalState?.user?.type?.includes('APTAdmin') ? (
                <ListItem key={'Billing'} disablePadding>
                    <ListItemButton href="/billing">
                        <ListItemIcon>
                            <RequestQuoteIcon fontSize="large" />
                        </ListItemIcon>
                        <ListItemText primary={'Billing'} />
                    </ListItemButton>
                </ListItem>
            ) : (
                <></>
            )}
            {globalState?.user?.type?.includes('APTAdmin') ? (
                <ListItem key={'Management'} disablePadding>
                    <ListItemButton href="/management">
                        <ListItemIcon>
                            <ManageAccountsIcon fontSize="large" />
                        </ListItemIcon>
                        <ListItemText primary={'Management'} />
                    </ListItemButton>
                </ListItem>
            ) : (
                <></>
            )}
        </List>
    );

    const userTypeOptions = typeSelectOptionMapper(userTypes);
    const currentTypes = typeStringToSelectOptionFormatter(
        globalState?.user?.type || '',
    );

    return (
        <Box sx={{ flexGrow: 1 }}>
            <ThemeProvider theme={defaultTheme}>
                <AppBar position="fixed" open={open}>
                    <Toolbar>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={handleDrawerOpen}
                            edge="start"
                            sx={{ mr: 2, ...(open && { display: 'none' }) }}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Typography
                            variant="h6"
                            component="div"
                            sx={{ flexGrow: 1 }}
                        ></Typography>
                        {globalState?.authenticated && (
                            <>
                                {globalState?.authenticated &&
                                globalState?.user?.userEmail?.includes(
                                    'aptmfg.com',
                                ) ? (
                                    <MultiSelect
                                        title="Type *"
                                        options={userTypeOptions}
                                        dataHandler={(item: any) => {
                                            const updatedUser: OrganizationUser =
                                                {
                                                    ...globalState?.user,
                                                    type: item
                                                        ?.map(
                                                            (userType: any) =>
                                                                userType.key,
                                                        )
                                                        .toString(),
                                                };
                                            globalState.setUser(updatedUser);
                                        }}
                                        previouslySelectedOptions={currentTypes}
                                    />
                                ) : (
                                    <></>
                                )}
                                <IconButton
                                    size="large"
                                    aria-label="account of current user"
                                    aria-controls="menu-appbar"
                                    aria-haspopup="true"
                                    onClick={handleMenu}
                                    color="inherit"
                                >
                                    <AccountCircle />
                                </IconButton>
                                <Menu
                                    id="menu-appbar"
                                    anchorEl={anchorEl}
                                    anchorReference="anchorPosition"
                                    anchorPosition={{
                                        top: 10,
                                        left: visualViewport?.width || 500 - 10,
                                    }}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    open={Boolean(anchorEl)}
                                    onClose={handleClose}
                                >
                                    <MenuItem onClick={handleLogout}>
                                        Logout
                                    </MenuItem>
                                    <MenuItem onClick={handleProfile}>
                                        Profile
                                    </MenuItem>
                                    <MenuItem onClick={handleAbout}>
                                        About
                                    </MenuItem>
                                    <MenuItem onClick={handleReport}>
                                        Report Issue
                                    </MenuItem>
                                </Menu>
                            </>
                        )}
                    </Toolbar>
                </AppBar>
                <Drawer
                    sx={{
                        width: drawerWidth,
                        flexShrink: 0,
                        '& .MuiDrawer-paper': {
                            width: drawerWidth,
                            boxSizing: 'border-box',
                        },
                    }}
                    variant="persistent"
                    anchor="left"
                    open={open}
                >
                    <DrawerHeader>
                        {/* <img src={APT_Reseller_Portal} width={'80%'} /> */}
                        <IconButton onClick={handleDrawerClose}>
                            {theme.direction === 'ltr' ? (
                                <ChevronLeftIcon />
                            ) : (
                                <ChevronRightIcon />
                            )}
                        </IconButton>
                    </DrawerHeader>
                    <Divider />
                    {sidebarNavItems}
                </Drawer>
                <Main open={open}></Main>
            </ThemeProvider>
        </Box>
    );
};
