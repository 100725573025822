import React from 'react';
import '../../styles/MultipleChoice.css';
import { ModulePage, Question, UserAnswers } from '../../interfaces/Interfaces';
import { Button } from '@mui/material';
import { Textarea, editUser } from '../../utilities/Utilites';
import { useGlobalStore } from '../../utilities/GlobalState';
import { AuthPost } from '../../utilities/Routing/Requests';
import { toast } from 'react-toastify';
import assignments from '../../styles/assets/assignments.jpg';

interface Props {
    questions: Array<Question> | undefined;
    answers: Array<UserAnswers | null>;
    setAnswers: (answers: Array<UserAnswers | null>) => void;
    page: ModulePage;
    setDisableForward: (disable: boolean) => void;
    classID?: number;
    finalPage: boolean;
    setViewModuleDetails: (page: number) => void;
}

export const MultipleChoice = ({
    questions,
    answers,
    setAnswers,
    page,
    setDisableForward,
    classID,
    finalPage,
    setViewModuleDetails,
}: Props) => {
    const [questionsLocal, setQuestionsLocal] =
        React.useState<Array<Question>>();
    const [activeQuestion, setActiveQuestion] = React.useState(0);
    const [showSubmitButton, setShowSubmitButton] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    // const [selectedAnswer, setSelectedAnswer] = React.useState(false);
    const [showResult, setShowResult] = React.useState(false);
    const [currentSavedAnswer, setCurrentSavedAnswer] =
        React.useState<any>(null);
    const [currentModuleProgress, setCurrentModuleProgress] = React.useState<
        string | undefined
    >();
    const [allAnswers, setAllAnswers] = React.useState<
        Array<UserAnswers | null>
    >(answers || []);
    const [selectedAnswerIndex, setSelectedAnswerIndex] =
        React.useState<any>(null);
    // const [result, setResult] = React.useState({
    //     score: 0,
    //     correctAnswers: 0,
    //     wrongAnswers: 0,
    // });

    const globalState = useGlobalStore((state) => state);
    const checkModuleProgress = (
        moduleID: number,
        modulePageID: number,
        userID?: number,
    ) => {
        const progress = AuthPost(
            '/progress/getuserprogress',
            {
                classID: classID,
                userID: userID || globalState?.user?.id,
                moduleID: moduleID,
                modulePageID: modulePageID,
            },
            {
                'x-access-token': globalState?.authToken || '',
                canCache: true,
            },
        )
            .then((res) => {
                if (res?.status === 200 || res?.status === 204) {
                    return res.data;
                } else {
                    return [];
                }
            })
            .catch((err) => {
                console.error(err.toString());
                toast.error(err.toString());
            });
        return progress.then((res) => {
            if (res?.recordset?.length) {
                return res?.recordset?.every(
                    (obj: any) => obj?.status === 'complete',
                );
            } else {
                return false;
            }
        });
    };

    const completeModule = () => {
        AuthPost(
            '/progress/updateprogress',
            {
                classID: classID,
                userID: globalState?.user?.id,
                moduleID: Number(page.moduleId),
                status: 'complete',
            },
            {
                'x-access-token': globalState?.authToken || '',
                canCache: true,
            },
        )
            .then((res) => {
                if (res?.status === 200 || res?.status === 204) {
                    setViewModuleDetails(0);
                    return res.data;
                } else {
                    setViewModuleDetails(0);
                    return [];
                }
            })
            .catch((err) => {
                setViewModuleDetails(0);
                console.error(err.toString());
                toast.error(err.toString());
            });
    };
    React.useEffect(() => {
        checkModuleProgress(
            Number(page?.moduleId),
            page?.id,
            Number(globalState?.user?.id),
        ).then((progress) => {
            if (finalPage && showResult && !progress) {
                setShowSubmitButton(true);
            }
        });
    }, [showResult, finalPage]);
    React.useEffect(() => {
        if (questions) {
            setLoading(true);
            const qs: any = Object.keys(questions).map(
                (qkey: any) => questions[qkey],
            );
            setQuestionsLocal(qs);
            setShowResult(false);
            if (currentSavedAnswer || allAnswers?.[activeQuestion]) {
                setDisableForward(false);
            } else {
                setDisableForward(true);
            }
            setLoading(false);
        }
    }, [questions]);
    React.useEffect(() => {
        if (questions) {
            const qs: any = Object.keys(questions).map(
                (qkey: any) => questions[qkey],
            );
            setLoading(true);
            const getBody = {
                userID: globalState?.user?.id,
                assessmentDetailID:
                    qs?.[activeQuestion || 0]?.assessmentDetailID,
            };

            checkModuleProgress(
                Number(page?.moduleId),
                page?.id,
                Number(globalState?.user?.id),
            ).then((progress: any) => {
                return AuthPost('/gradebook/getusergradebookdetail', getBody, {
                    'x-access-token': globalState.authToken,
                    canCache: true,
                })
                    .then((res) => {
                        if (res?.status === 200 || res?.status === 204) {
                            if (Number(res?.data?.[0]?.answer)) {
                                setSelectedAnswerIndex(
                                    Number(res?.data?.[0]?.answer),
                                );
                            }
                            setCurrentSavedAnswer(
                                res?.data?.[res?.data?.length - 1]?.answer,
                            );
                            setCurrentModuleProgress(progress);
                            // const current Questions: Array<any> = questions;
                            // currentQuestions.push({
                            //     assessmentDetailID: body.assessmentDetailID,
                            //     question: '',
                            //     choices: [''],
                            //     type: type,
                            //     correctAnswer: [],
                            // });
                            return res.data;
                        } else {
                            return [];
                        }
                    })
                    .then((res1) => {
                        setLoading(false);
                        return res1;
                    })
                    .catch((err) => {
                        setLoading(false);
                        toast.error(err.toString());
                    });
            });
        }
    }, []);
    React.useEffect(() => {
        if (questions) {
            const qs: any = Object.keys(questions).map(
                (qkey: any) => questions[qkey],
            );
            setLoading(true);
            const getBody = {
                userID: globalState?.user?.id,
                assessmentDetailID:
                    qs?.[activeQuestion || 0]?.assessmentDetailID,
            };
            AuthPost('/gradebook/getusergradebookdetail', getBody, {
                'x-access-token': globalState.authToken,
                canCache: true,
            })
                .then((res) => {
                    if (res?.status === 200 || res?.status === 204) {
                        if (Number(res?.data?.[0]?.answer)) {
                            setSelectedAnswerIndex(
                                Number(res?.data?.[0]?.answer),
                            );
                        }
                        setCurrentSavedAnswer(
                            res?.data?.[res?.data?.length - 1]?.answer,
                        );
                        // const current Questions: Array<any> = questions;
                        // currentQuestions.push({
                        //     assessmentDetailID: body.assessmentDetailID,
                        //     question: '',
                        //     choices: [''],
                        //     type: type,
                        //     correctAnswer: [],
                        // });
                        return res.data;
                    } else {
                        return [];
                    }
                })
                .then((res1) => {
                    setLoading(false);
                    return res1;
                })
                .catch((err) => {
                    setLoading(false);
                    toast.error(err.toString());
                });
        }
    }, [activeQuestion]);
    const image = questionsLocal?.[activeQuestion]?.image;
    const question =
        questionsLocal?.[activeQuestion]?.question ||
        questionsLocal?.[activeQuestion]?.assessmentDetail;
    const choices =
        questionsLocal?.[activeQuestion]?.choices ||
        questionsLocal?.[activeQuestion]?.assessmentChoices;
    const correctAnswer = questionsLocal?.[activeQuestion]?.correctAnswer;
    const type = questionsLocal?.[activeQuestion]?.type;

    const addAnswer = async (answer: number | null) => {
        const currentDate = new Date();
        const questionType = questionsLocal?.[activeQuestion]?.type;
        let mcCorrect: undefined | boolean = undefined;
        if (questionType === 'mc') {
            let mappedCorrectAnswers: Array<string> = [];
            if (correctAnswer?.length && questionsLocal?.length) {
                mappedCorrectAnswers = correctAnswer?.map((answerInd: any) => {
                    return (answerInd - 1).toString();
                });
            }
            if (
                answer !== undefined &&
                answer !== null &&
                mappedCorrectAnswers.includes(answer.toString())
            ) {
                mcCorrect = true;
            } else {
                mcCorrect = false;
            }
        }
        const body = {
            classID: classID,
            userID: globalState.user?.id,
            moduleID: page?.moduleId,
            modulePageID: page?.id,
            assessmentDetailID: questions?.[activeQuestion]?.assessmentDetailID,
            answer: answer?.toString(),
            posted: currentDate.toISOString(),
            correct: mcCorrect,
        };
        await AuthPost('/gradebook/addgradebook', body, {
            'x-access-token': globalState.authToken,
            canCache: false,
        })
            .then((res) => {
                if (res?.status === 200 || res?.status === 204) {
                    // const current Questions: Array<any> = questions;
                    // currentQuestions.push({
                    //     assessmentDetailID: body.assessmentDetailID,
                    //     question: '',
                    //     choices: [''],
                    //     type: type, XV6YaIG
                    //     correctAnswer: [],
                    // });
                    return res.data;
                } else {
                    return [];
                }
            })
            .catch((err) => {
                toast.error(err.toString());
                setLoading(false);
            });
        const getBody = {
            classID: classID,
            userID: globalState.user?.id,
        };
        const getGBBody = {
            userID: globalState?.user?.id,
            assessmentDetailID:
                questionsLocal?.[activeQuestion + 1]?.assessmentDetailID,
        };
        await AuthPost('/gradebook/getusergradebookdetail', getGBBody, {
            'x-access-token': globalState.authToken,
            canCache: true,
        })
            .then((res) => {
                if (res?.status === 200 || res?.status === 204) {
                    if (res?.data?.type === 'mc') {
                        setSelectedAnswerIndex(
                            res?.data?.[0]?.answer?.toNumber(),
                        );
                    }
                    setCurrentSavedAnswer(
                        res?.data?.[res?.data?.length - 1]?.answer,
                    );
                    // const current Questions: Array<any> = questions;
                    // currentQuestions.push({
                    //     assessmentDetailID: body.assessmentDetailID,
                    //     question: '',
                    //     choices: [''],
                    //     type: type,
                    //     correctAnswer: [],
                    // });
                    return res.data;
                } else {
                    return [];
                }
            })
            .catch((err) => {
                toast.error(err.toString());
                setLoading(false);
            });

        await AuthPost('/gradebook/getusergradebook', getBody, {
            'x-access-token': globalState.authToken,
            canCache: true,
        })
            .then((res) => {
                if (res?.status === 200 || res?.status === 204) {
                    // const current Questions: Array<any> = questions;
                    // currentQuestions.push({
                    //     assessmentDetailID: body.assessmentDetailID,
                    //     question: '',
                    //     choices: [''],
                    //     type: type,
                    //     correctAnswer: [],
                    // });
                    return res.data;
                } else {
                    return [];
                }
            })
            .catch((err) => {
                toast.error(err.toString());
                setLoading(false);
            });
        let currentAnswerObject = answers.filter(
            (ans) => ans?.moduleId === page.moduleId && ans?.pageId === page.id,
        )[0];
        const currentAnswersArray = currentAnswerObject?.answers || [];
        currentAnswersArray.push(answer);
        currentAnswerObject = {
            ...currentAnswerObject,
            moduleId: currentAnswerObject?.moduleId || Number(page.moduleId),
            pageId: currentAnswerObject?.pageId || page.id,
            answers: currentAnswersArray,
        };
        setAnswers(currentAnswersArray);
        const currentAllAnswers: Array<UserAnswers> = [];
        answers.forEach((answ) => {
            if (
                answ &&
                answ?.moduleId !== page.moduleId &&
                answ?.pageId !== page.id &&
                currentAnswerObject
            ) {
                currentAllAnswers.push(currentAnswerObject);
            }
        });
        if (currentAllAnswers && answers) {
            currentAllAnswers.push(currentAnswerObject);
        }
        setAnswers(currentAllAnswers);
        setAllAnswers(currentAllAnswers);
        setLoading(false);
    };
    const newAnswers = allAnswers?.length
        ? JSON.stringify(allAnswers)
        : globalState?.user?.answers || '';
    const submitAnswers = () => {
        if (globalState.user) {
            editUser(
                globalState.user.userEmail,
                globalState.user.firstName,
                globalState.user.lastName,
                globalState.user.orgId || '',
                globalState.user.token || '',
                globalState.user.type || '',
                globalState.user.students || '',
                globalState.user.instructor || '',
                globalState.user.classes || '',
                newAnswers,
            );
        }
    };

    const onClickNext = () => {
        setLoading(true);
        addAnswer(selectedAnswerIndex);
        setSelectedAnswerIndex(null);
        if (
            questionsLocal?.length &&
            activeQuestion !== questionsLocal?.length - 1
        ) {
            setDisableForward(true);
            setActiveQuestion(activeQuestion + 1);
            setLoading(false);
        } else {
            setDisableForward(false);
            submitAnswers();
            setActiveQuestion(0);
            setShowResult(true);
            setLoading(false);
        }
    };

    const onAnswerSelected = (
        answer: string,
        ansType: string,
        index: number,
    ) => {
        setLoading(true);
        if (ansType === 'mc') {
            setSelectedAnswerIndex(index);
            setLoading(false);
            // correctAnswer?.forEach((ans: number) => {
            //     if (answer === choices?.[ans - 1]) {
            //         setSelectedAnswer(true);
            //     } else {
            //         setSelectedAnswer(false);
            //     }
            // });
        } else {
            setSelectedAnswerIndex(answer);
            setLoading(false);
            // setSelectedAnswer(true);
        }
    };

    const addLeadingZero = (number: number) =>
        number > 9 ? number : `0${number}`;

    const disableNext = () => {
        if (
            currentSavedAnswer ||
            allAnswers?.[activeQuestion] ||
            !currentModuleProgress
        ) {
            return false;
        } else if (selectedAnswerIndex === null) {
            return true;
        } else {
            return false;
        }
    };

    const getClassName = (index: number) => {
        return !selectedAnswerIndex && currentSavedAnswer === index.toString()
            ? 'selected-answer'
            : selectedAnswerIndex === index
            ? 'selected-answer'
            : '';
    };

    return !loading ? (
        <div
            className="quiz-main"
            style={{
                backgroundImage: `url(${assignments})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
            }}
        >
            <div className="quiz-container">
                {!showResult && !loading ? (
                    <div>
                        <div>
                            <span className="active-question-no">
                                {addLeadingZero(activeQuestion + 1)}
                            </span>
                            <span className="total-question">
                                /{addLeadingZero(questionsLocal?.length || 0)}
                            </span>
                        </div>
                        {image ? (
                            <div>
                                <img
                                    style={{ width: '100%' }}
                                    src={image || ''}
                                    alt="Missing Image"
                                />
                            </div>
                        ) : (
                            <></>
                        )}
                        <h2>{question}</h2>
                        {type === 'mc' ? (
                            <ul>
                                {choices?.map(
                                    (choice: string, index: number) => {
                                        return !loading ? (
                                            <li
                                                onClick={() => {
                                                    if (
                                                        (currentSavedAnswer ||
                                                            allAnswers?.[
                                                                activeQuestion
                                                            ]) &&
                                                        currentModuleProgress
                                                    ) {
                                                        return () => {
                                                            return;
                                                        };
                                                    } else {
                                                        return () =>
                                                            onAnswerSelected(
                                                                choice,
                                                                type,
                                                                index,
                                                            );
                                                    }
                                                }}
                                                key={choice + index}
                                                className={getClassName(index)}
                                            >
                                                {choice}
                                            </li>
                                        ) : (
                                            <></>
                                        );
                                    },
                                )}
                            </ul>
                        ) : type === 'sa' ? (
                            <Textarea
                                style={{
                                    width: '800px',
                                    maxWidth: '100%',
                                    maxHeight: '500px',
                                }}
                                required
                                name="shortAnswer"
                                onChange={(e) => {
                                    const choice = e.target.value;
                                    return onAnswerSelected(choice, type, 0);
                                }}
                                defaultValue={
                                    currentSavedAnswer ||
                                    allAnswers?.[activeQuestion]
                                }
                                disabled={
                                    (currentSavedAnswer ||
                                        allAnswers?.[activeQuestion]) &&
                                    currentModuleProgress
                                }
                                minRows={3}
                                maxRows={10}
                                placeholder="Short Answer"
                                // value={content || ''}
                            />
                        ) : type === 'la' ? (
                            <>
                                <Textarea
                                    style={{
                                        width: '800px',
                                        maxWidth: '100%',
                                        maxHeight: '500px',
                                    }}
                                    required
                                    minRows={3}
                                    maxRows={10}
                                    placeholder="Long Answer"
                                    // value={content || ''}
                                    name="longAnswer"
                                    defaultValue={
                                        currentSavedAnswer ||
                                        allAnswers?.[activeQuestion]
                                    }
                                    onChange={(e) => {
                                        const choice = e.target.value;
                                        return onAnswerSelected(
                                            choice,
                                            type,
                                            0,
                                        );
                                    }}
                                    disabled={
                                        (currentSavedAnswer ||
                                            allAnswers?.[activeQuestion]) &&
                                        currentModuleProgress
                                    }
                                />
                            </>
                        ) : (
                            <></>
                        )}
                        <div className="flex-right">
                            <button
                                onClick={onClickNext}
                                disabled={disableNext()}
                            >
                                {activeQuestion === questionsLocal?.length
                                    ? 'Finish'
                                    : 'Next'}
                            </button>
                        </div>
                    </div>
                ) : (
                    <div className="result">
                        <h3>Answers Saved Successfully!</h3>
                        {/* <p>
                            Total Question: <span>{questions.length}</span>
                        </p>
                        <p>
                            Total Score:<span> {result.score}</span>
                        </p>
                        <p>
                            Correct Answers:
                            <span> {result.correctAnswers}</span>
                        </p>
                        <p>
                            Wrong/Ungraded Answers:
                            <span> {result.wrongAnswers}</span>
                        </p> */}
                        <>
                            {showSubmitButton ? (
                                <Button
                                    fullWidth
                                    variant="contained"
                                    sx={{ mt: 3, mb: 2 }}
                                    onClick={completeModule}
                                >
                                    SUBMIT TO BE GRADED
                                </Button>
                            ) : (
                                <></>
                            )}
                        </>
                    </div>
                )}
            </div>
        </div>
    ) : (
        <></>
    );
};
