import * as React from 'react';
import Card from '@mui/material/Card';
import { Checkbox, TextField } from '@mui/material';
import { AuthPost } from '../utilities/Routing/Requests';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import { toast } from 'react-toastify';

interface Props {
    question: string;
    answer: string;
    type: string;
    authToken: string;
    correct?: boolean;
    userID?: number;
    assessmentDetailID: string;
    classID?: number;
    userType: string;
    correctAnswersMC: Array<number>;
    assessmentChoices: Array<string>;
    instructorFeedback: string;
    gradebookID: number;
}

const GradebookAnswerCard = ({
    question,
    answer,
    type,
    correct,
    authToken,
    userID,
    assessmentDetailID,
    classID,
    userType,
    correctAnswersMC,
    assessmentChoices,
    instructorFeedback,
    gradebookID,
}: Props) => {
    const [correctAnswer, setCorrectAnswer] = React.useState<string>();
    React.useEffect(() => {
        if (type === 'mc' && correctAnswersMC?.length) {
            let mappedCorrectAnswers: Array<string> = [];
            if (correctAnswersMC?.length) {
                mappedCorrectAnswers = correctAnswersMC?.map((answerInd) => {
                    return assessmentChoices?.[answerInd - 1];
                });
            }
            if (answer === undefined || answer === null) {
                setCorrectAnswer(undefined);
            } else if (mappedCorrectAnswers.includes(answer)) {
                setCorrectAnswer('yes');
            } else {
                setCorrectAnswer('no');
            }
        } else if (correct === true) {
            setCorrectAnswer('yes');
        } else if (correct === undefined || answer === null) {
            setCorrectAnswer(undefined);
        } else if (correct === false) {
            setCorrectAnswer('no');
        }
    }, [correctAnswersMC]);
    const typeMapped = () => {
        if (type === 'mc') {
            return 'Multiple Choice';
        } else if (type === 'sa') {
            return 'Short Answer';
        } else if (type === 'la') {
            return 'Long Answer';
        } else {
            return '';
        }
    };

    const handleCorrectChange = (newAnswer: any) => {
        setCorrectAnswer(newAnswer);
        const body = {
            userID: userID,
            assessmentDetailID: assessmentDetailID,
            correct: newAnswer === 'yes' ? true : false,
            classID: classID,
        };

        AuthPost('/gradebook/gradebookcorrect', body, {
            'x-access-token': authToken,
            canCache: true,
        })
            .then((res) => {
                if (res?.status === 200 || res?.status === 204) {
                    return res.data;
                } else {
                    return res;
                }
            })
            .catch((err) => {
                console.error(err.toString());
                toast.error(err.toString());
            });
    };

    const handleFeedbackChange = (e: any) => {
        const body = {
            gradebookID: gradebookID,
            feedback: e?.target?.value,
        };

        AuthPost('/gradebook/gradebookfeedback', body, {
            'x-access-token': authToken,
            canCache: true,
        })
            .then((res) => {
                if (res?.status === 200 || res?.status === 204) {
                    return res.data;
                } else {
                    return res;
                }
            })
            .catch((err) => {
                console.error(err.toString());
                toast.error(err.toString());
            });
    };

    return (
        <Card
            sx={{
                margin: '0 10px 0 10px',
                width: '100%',
                // height: '300px',
                // display: 'flex',
                // flexDirection: 'row',
                // backgroundImage: backImg as string,
                textAlignLast: 'left',
            }}
        >
            <div
                style={{
                    margin: '10px 10px 10px 10px',
                }}
            >
                Question: {question}
            </div>
            <div
                style={{
                    margin: '10px 10px 10px 10px',
                    // height: '50px',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                }}
            >
                {'Answer: '}
                <span
                    style={{
                        fontWeight: 'bolder',
                    }}
                >
                    {answer}
                </span>
            </div>
            <div
                style={{
                    margin: '10px 10px 10px 10px',
                }}
            >
                Type: {typeMapped()}
            </div>
            {userType !== 'Student' ? (
                <div
                    style={{
                        margin: '10px 10px 10px 10px',
                    }}
                >
                    Correct:{' '}
                    <Checkbox
                        checked={correctAnswer === 'yes'}
                        onChange={() => handleCorrectChange('yes')}
                    />
                    Incorrect:{' '}
                    <Checkbox
                        checked={correctAnswer === 'no'}
                        onChange={() => handleCorrectChange('no')}
                    />
                    <TextField
                        fullWidth
                        label="Instructor Feedback"
                        multiline={true}
                        defaultValue={instructorFeedback}
                        id="instructorFeedback"
                        name="instructorFeedback"
                        aria-label="Instructor Feedback"
                        placeholder="Instructor Feedback"
                        onBlur={handleFeedbackChange}
                    />
                </div>
            ) : (
                <>
                    <div
                        style={{
                            margin: '10px 10px 10px 10px',
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        {correctAnswer === 'yes' ? (
                            <>
                                <span style={{ marginBottom: '4px' }}>
                                    {'Correct '}
                                </span>
                                <CheckIcon color="success" />
                            </>
                        ) : correctAnswer === 'no' ? (
                            <>
                                <span style={{ marginBottom: '4px' }}>
                                    {'Incorrect '}
                                </span>
                                <ClearIcon color="error" />
                            </>
                        ) : (
                            <>
                                <span style={{ marginBottom: '4px' }}>
                                    {'Incomplete '}
                                </span>
                                <ClearIcon color="error" />
                            </>
                        )}
                        <br />
                    </div>
                    <div
                        style={{
                            margin: '10px 10px 10px 10px',
                        }}
                    >
                        Instructor Feedback: <b>{instructorFeedback}</b>
                    </div>
                </>
            )}
        </Card>
    );
};

export { GradebookAnswerCard };
