import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import React, { useState } from 'react';
import { MultiSelectOption } from '../interfaces/Interfaces';

interface Props {
    title: string;
    options: Array<MultiSelectOption>;
    dataHandler: (data: Array<MultiSelectOption>) => void;
    previouslySelectedOptions?: Array<MultiSelectOption>;
    maxHeight?: number;
}
export const MultiSelect = ({
    title,
    options,
    dataHandler,
    previouslySelectedOptions,
    maxHeight,
}: Props) => {
    const [selected, setSelected] = useState<Array<MultiSelectOption>>([]);
    const [selectOptionsRender, setSelectOptionsRender] = useState<Array<any>>(
        [],
    );
    const [forceRefresh, setForceRefresh] = useState(false);

    const selectOptions = () => {
        setSelectOptionsRender(
            options.map((option) => {
                const selectedCheck =
                    selected.filter((opt) => opt.option === option.option)
                        ?.length > 0;
                return (
                    <MenuItem key={option.key} value={option.option}>
                        <ListItemIcon>
                            <Checkbox checked={selectedCheck} />
                        </ListItemIcon>
                        <ListItemText primary={option.option} />
                    </MenuItem>
                );
            }),
        );
    };
    React.useEffect(() => {
        selectOptions();
        if (previouslySelectedOptions) {
            setSelected(previouslySelectedOptions);
        }
    }, []);

    React.useEffect(() => {
        if (forceRefresh) {
            selectOptions();
        }
    }, [forceRefresh]);
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProperties: Partial<MenuProps> = {
        PaperProps: {
            style: {
                maxHeight: maxHeight || ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            },
        },
        anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
        },
        transformOrigin: {
            vertical: 'top',
            horizontal: 'center',
        },
        variant: 'menu',
    };

    const isAllSelected =
        options.length > 0 && selected.length === options.length;

    const handleChange = (event: any, child: any) => {
        const value = event.target.value;
        const itemName = child.props.value;
        if (value[value.length - 1] === 'all') {
            setSelected(selected.length === options.length ? [] : options);
            dataHandler(selected.length === options.length ? [] : options);
            return;
        }

        const option = options.filter((opts) => {
            return itemName === opts.option;
        });

        const selectedOption = selected.filter((opts) => {
            return itemName === opts.option;
        });

        if (selectedOption?.length > 0) {
            const removedOption = selected.filter((opts) => {
                return itemName !== opts.option;
            });
            setSelected(removedOption);
            dataHandler(removedOption);
        } else {
            const newArr = selected;
            newArr.push(option[0]);
            setSelected(newArr);
            dataHandler(newArr);
        }
        setForceRefresh(!forceRefresh);
    };

    return (
        <FormControl fullWidth>
            <InputLabel
                sx={{
                    marginTop: '16px',
                    marginBottom: '8px',
                }}
                id="mutiple-select-label"
            >
                {title}
            </InputLabel>
            <Select
                label={title}
                labelId="mutiple-select-label"
                multiple
                value={selected?.map((items) => items.option)}
                onChange={handleChange}
                renderValue={(item) => item.join(', ')}
                MenuProps={MenuProperties}
                sx={{ marginTop: '16px', marginBottom: '8px' }}
            >
                <MenuItem
                    value="all"
                    sx={{
                        root: isAllSelected
                            ? {
                                  backgroundColor: 'rgba(0, 0, 0, 0.08)',
                                  '&:hover': {
                                      backgroundColor: 'rgba(0, 0, 0, 0.08)',
                                  },
                              }
                            : '',
                    }}
                >
                    <ListItemIcon>
                        <Checkbox
                            classes={{
                                indeterminate: '#f50057',
                            }}
                            checked={isAllSelected}
                            indeterminate={
                                selected.length > 0 &&
                                selected.length < options.length
                            }
                        />
                    </ListItemIcon>
                    <ListItemText
                        sx={{
                            primary: {
                                fontWeight: 500,
                            },
                        }}
                        primary="Select All"
                    />
                </MenuItem>
                {selectOptionsRender}
            </Select>
        </FormControl>
    );
};
