// Basic requirements for "courses"
//
// Instuctor can create and manage many courses
// Each course has a title, produces a "course code", and also has an invite student button.
// There should be assignments, roster, gradebook, and content tabs
// There should also be a content option for selecting and adding modules/content
// When adding a content item there should be start and due dates
//

import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Card, Grid } from '@mui/material';
import { EditModulePageModal } from '../modules/EditModulePageModal';
import { Module, ModulePage, Question } from '../interfaces/Interfaces';
import { AuthGet, AuthDelete, AuthPost } from '../utilities/Routing/Requests';
import { useGlobalStore } from '../utilities/GlobalState';
import { toast } from 'react-toastify';
import { ConfirmationModal } from '../utilities/Modals/ConfirmationModal';
import { CreateModuleModal } from '../modules/CreateModuleModal';
import { AssignmentsTab } from './AssignmentsTab';
import { ModulesTab } from './ModulesTab';
import { scrollToCurrentHeadingAnchor } from '../utilities/RichTextEditor/HeadingWithAnchor';
import { RichTextEditorRef } from 'mui-tiptap';
import { Editor } from '@tiptap/core';
import SliderSwitch from '../utilities/SliderSwitch';
import { ModulePreviewDetails } from '../classes/ModulePreviewDetails';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

const CustomTabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
};

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
const Modules = () => {
    const globalState = useGlobalStore((state) => state);
    const rteRef = React.useRef<RichTextEditorRef | any>(null);
    const [createModulePageOpen, setCreateModulePageOpen] =
        React.useState(false);
    const [cardView, setCardView] = React.useState(true);
    const [createModuleOpen, setCreateModuleOpen] = React.useState(false);
    const [value, setValue] = React.useState(0);
    const [viewModuleDetails, setViewModuleDetails] = React.useState(0);
    const [selectedClass, setSelectedClass] = React.useState(undefined);
    const [page, setPage] = React.useState<ModulePage | undefined>();
    const [pages, setPages] = React.useState<Array<ModulePage>>([]);
    const [modules, setModules] = React.useState<Array<Module>>([]);
    const [showDelete, setShowDelete] = React.useState<boolean>(false);
    const [showDeleteModule, setShowDeleteModule] =
        React.useState<boolean>(false);
    const [showModuleDetails, setShowModuleDetails] = React.useState<
        Module | undefined
    >(undefined);
    const [moduleDeleteId, setModuleDeleteId] = React.useState<
        number | undefined
    >();
    const [pageDeleteId, setPageDeleteId] = React.useState<
        number | undefined
    >();
    const [questions, setQuestions] = React.useState<Array<Question>>([]);
    // const [currentPage, setCurrentPage] = React.useState<number>(0);
    const [newPageType, setNewPageType] = React.useState<string>('');
    const [disableForward, setDisableForward] = React.useState<boolean>(false);

    const setNewCurrentPage = (newPage?: number) => {
        // setCurrentPage(newPage);
        globalState.setCurrentPage(newPage || 0);
    };

    const urlHashChange = () => {
        scrollToCurrentHeadingAnchor(
            rteRef.current.editor as Editor,
            globalState.setCurrentPage,
            globalState?.currentPage || 0,
        );
    };

    React.useEffect(() => {
        if (rteRef?.current?.editor) {
            urlHashChange();
        }
    }, [window.location.hash]);
    const getModules = async () => {
        let data: Array<any> = [];
        await AuthGet('/organization/modules', {
            'x-access-token': globalState.authToken,
            canCache: true,
        })
            .then((res) => {
                if (res?.status === 200) {
                    const filteredModules =
                        !globalState?.user?.type?.includes('Admin') &&
                        globalState?.user?.type?.includes('Instructor')
                            ? res?.data?.filter((mod: Module) => mod.active)
                            : res?.data;
                    data = filteredModules?.sort((a: any, b: any) => {
                        const codeA = a?.code?.toUpperCase(); // ignore upper and lowercase
                        const codeB = b?.code?.toUpperCase(); // ignore upper and lowercase
                        if (codeA < codeB) {
                            return -1;
                        }
                        if (codeA > codeB) {
                            return 1;
                        }

                        // names must be equal
                        return 0;
                    });
                    setModules(data);
                    return filteredModules;
                } else {
                    return [];
                }
            })
            .catch((err) => {
                toast.error(err.toString());
            });
        return data;
    };

    const getModulePages = async (moduleId: number) => {
        let data: Array<any> = [];
        return AuthPost(
            '/organization/modulepages',
            { moduleId: moduleId },
            {
                'x-access-token': globalState.authToken,
                canCache: true,
            },
        )
            .then((res) => {
                if (res?.status === 200) {
                    data = res?.data?.sort(
                        (a: any, b: any) =>
                            Number(a.pageNumber) - Number(b.pageNumber),
                    );
                    setPages(data);
                    return res.data;
                } else {
                    return [];
                }
            })
            .catch((err) => {
                toast.error(err.toString());
            });
    };

    const getModulePageDetails = async (modulePageID: number) => {
        return AuthPost(
            '/assessmentdetail/getAllPageAssessments',
            { modulePageID: modulePageID },
            {
                'x-access-token': globalState.authToken,
                canCache: true,
            },
        )
            .then((res) => {
                if (res?.status === 200) {
                    return res?.data;
                } else {
                    return [];
                }
            })
            .catch((err) => {
                toast.error(err.toString());
            });
    };

    const deletePage = () => {
        return AuthDelete(`/organization/deletepage/${pageDeleteId}`, {
            'x-access-token': globalState.authToken,
            canCache: true,
        })
            .then((res) => {
                setShowDelete(false);
                return res;
            })
            .catch((err) => {
                toast.error(err.toString());
            });
    };

    const deleteModule = () => {
        return AuthDelete(`/organization/deletemodule/${moduleDeleteId}`, {
            'x-access-token': globalState.authToken,
            canCache: true,
        })
            .then((res) => {
                setViewModuleDetails(0);
                getModules();
                setShowDeleteModule(false);
                return res;
            })
            .catch((err) => {
                toast.error(err.toString());
            });
    };

    const handleToggleCreateModule = (refresh?: boolean) => {
        const current = createModuleOpen;
        setCreateModuleOpen(!current);
        if (refresh) {
            getModules();
        }
    };

    const handleToggleCreateModulePage = (edit?: boolean, newType?: string) => {
        if (!edit) {
            setPage(undefined);
        }
        if (newType) {
            setNewPageType(newType);
        }
        const current = createModulePageOpen;
        setCreateModulePageOpen(!current);
    };

    const handleToggleCreateAssignmentPage = (
        edit?: boolean,
        newType?: string,
    ) => {
        const current = createModulePageOpen;
        if (!edit) {
            setQuestions([]);
            setPage(undefined);
        }
        if (newType) {
            setNewPageType(newType);
        }
        setCreateModulePageOpen(!current);
    };

    // const toggleEditPage = (modPage: ModulePage) => {
    //     setPage(modPage);
    //     if (modPage?.data?.startsWith('{"questions":')) {
    //         // build questions from assessment details instead of this weird object
    //         console.log('modPage', modPage);
    //         setQuestions(JSON.parse(modPage?.data)?.questions);
    //     }
    // };

    const toggleEditPage = (modPage: ModulePage) => {
        setPage(modPage);
        if (modPage?.data?.startsWith('{"questions":')) {
            const id = modPage?.id;
            getModulePageDetails(id).then((res) => {
                if (res?.length) {
                    const parsedRes = res?.map((q: any) => {
                        return {
                            ...q,
                            correctAnswer: JSON.parse(q.correctAnswer) || [],
                            assessmentChoices: JSON.parse(q.assessmentChoices),
                        };
                    });
                    setQuestions(parsedRes);
                } else {
                    const mod = modPage?.data as any;
                    setQuestions(JSON.parse(mod)?.questions);
                }
                handleToggleCreateModulePage(true);
            });
            // build questions from assessment details instead of this weird object
        } else {
            handleToggleCreateModulePage(true);
        }
    };

    const toggleDeletePageModal = (
        id: number | undefined,
        refresh?: boolean,
    ) => {
        setPageDeleteId(id);
        const current = showDelete;
        setShowDelete(!current);
        if (refresh) {
            getModulePages(viewModuleDetails);
        }
    };

    const toggleDeleteModuleModal = (id: number | undefined) => {
        setModuleDeleteId(id);
        const current = showDeleteModule;
        setShowDeleteModule(!current);
    };

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    React.useEffect(() => {
        getModules();
    }, []);

    return (
        <Box
            sx={{
                paddingTop: '30px',
                margin: '10px 10px 10px 10px',
                maxWidth: '100vp',
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            {!showDelete ? (
                <></>
            ) : (
                <ConfirmationModal
                    open={showDelete}
                    handleClose={toggleDeletePageModal}
                    title={'Confirm Page Deletion'}
                    confirmationText={
                        'Are you sure you want to permanently DELETE this page? This action is irreversible.'
                    }
                    confirmationButtonText={'DELETE'}
                    confirmationButtonAction={deletePage}
                />
            )}
            {!createModuleOpen ? (
                <></>
            ) : (
                <CreateModuleModal
                    open={createModuleOpen}
                    handleClose={handleToggleCreateModule}
                />
            )}
            {!showDeleteModule ? (
                <></>
            ) : (
                <ConfirmationModal
                    open={showDeleteModule}
                    handleClose={toggleDeleteModuleModal}
                    title={'Confirm Module Deletion'}
                    confirmationText={
                        'Are you sure you want to permanently DELETE this module? All associated pages will be deleted as well. This action is irreversible.'
                    }
                    confirmationButtonText={'DELETE'}
                    confirmationButtonAction={deleteModule}
                />
            )}
            <EditModulePageModal
                getModulePages={getModulePages}
                open={createModulePageOpen}
                handleClose={handleToggleCreateModulePage}
                moduleId={viewModuleDetails}
                page={page}
                setPage={setPage}
                setCreateModulePageOpen={setCreateModulePageOpen}
                questions={questions}
                setQuestions={setQuestions}
                newType={newPageType}
                rteRef={rteRef}
                urlHashChange={urlHashChange}
            />
            {/* <EditAssignmentPageModal
                getModulePages={getModulePages}
                open={createAssignmentPageOpen}
                handleClose={handleToggleCreateAssignmentPage}
                moduleId={viewModuleDetails}
                page={page}
                setPage={setPage}
                setCreateAssignmentPageOpen={setCreateAssignmentPageOpen}
                questions={questions}
                setQuestions={setQuestions}
            /> */}
            <Card
                sx={{
                    maxWidth: '100%',
                    // padding: '20px',
                    position: 'relative',
                    overflow: 'auto',
                    height: '100%',
                }}
            >
                {viewModuleDetails === 0 ? (
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Grid
                            container
                            // spacing={1}
                            // key={'slider-root-grid-container'}
                        >
                            <Grid
                                xs={8}
                                sx={{
                                    paddingTop: '10px',
                                    paddingLeft: '20px',
                                    paddingRight: '20px',
                                }}
                            >
                                <Tabs
                                    value={value}
                                    onChange={handleChange}
                                    aria-label="Course Tabs"
                                >
                                    <Tab label="View" {...a11yProps(0)} />
                                    {globalState?.user?.type?.includes(
                                        'APTAdmin',
                                    ) ? (
                                        <Tab label="Edit" {...a11yProps(1)} />
                                    ) : (
                                        <></>
                                    )}
                                </Tabs>
                            </Grid>
                            <Grid
                                xs={4}
                                sx={{
                                    paddingTop: '10px',
                                    paddingLeft: '20px',
                                    paddingRight: '20px',
                                    textAlignLast: 'right',
                                }}
                            >
                                Card
                                <SliderSwitch
                                    sx={{ m: 1 }}
                                    value={cardView}
                                    onChange={() => setCardView(!cardView)}
                                />
                                Table
                            </Grid>
                        </Grid>
                    </Box>
                ) : (
                    <></>
                )}
                <CustomTabPanel value={value} index={0}>
                    {showModuleDetails ? (
                        <>
                            <ModulePreviewDetails
                                module={showModuleDetails}
                                toggleModuleDetails={() => {
                                    setShowModuleDetails(undefined);
                                }}
                            />
                        </>
                    ) : (
                        <AssignmentsTab
                            cardView={cardView}
                            questions={questions}
                            modules={modules}
                            pages={pages}
                            currentPage={globalState?.currentPage || 0}
                            setCurrentPage={setNewCurrentPage}
                            setViewModuleDetailsParent={setViewModuleDetails}
                            getModulePages={getModulePages}
                            setPages={setPages}
                            disableForward={disableForward}
                            setDisableForward={setDisableForward}
                            classID={selectedClass}
                            overViewsOnly={
                                !globalState?.user?.type?.includes('Admin') &&
                                globalState?.user?.type?.includes('Instructor')
                            }
                            toggleBack={() => {
                                setViewModuleDetails(0);
                                setSelectedClass(undefined);
                            }}
                        />
                    )}
                </CustomTabPanel>
                <CustomTabPanel value={value} index={1}>
                    <ModulesTab
                        cardView={cardView}
                        viewModuleDetails={viewModuleDetails}
                        handleToggleCreateModule={handleToggleCreateModule}
                        modules={modules}
                        setViewModuleDetails={setViewModuleDetails}
                        pages={pages}
                        setPages={setPages}
                        getModules={getModules}
                        getModulePages={getModulePages}
                        toggleEditPage={toggleEditPage}
                        toggleDeletePageModal={toggleDeletePageModal}
                        toggleDeleteModuleModal={toggleDeleteModuleModal}
                        handleToggleCreateModulePage={
                            handleToggleCreateModulePage
                        }
                        handleToggleCreateAssignmentPage={
                            handleToggleCreateAssignmentPage
                        }
                    />
                </CustomTabPanel>
            </Card>
        </Box>
    );
};

export { Modules };
