import { ThemeProvider } from '@mui/material/styles';
import { defaultTheme } from '../utilities/Utilites';
import * as React from 'react';
import Box from '@mui/system/Box';
import { Card, Grid, Typography } from '@mui/material';
import ITALogo01 from '../styles/assets/ITALogo01.png';
import { Classes } from '../classes/Classes';
import { toast } from 'react-toastify';
import { useGlobalStore } from '../utilities/GlobalState';
import { AuthPost } from '../utilities/Routing/Requests';
import { DateTime } from 'luxon';

export const LandingPage = () => {
    const globalState = useGlobalStore((state) => state);
    const [todo, setTodo] = React.useState<any>([]);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [selectedClass, setSelectedClass] = React.useState<number>();
    const [selectedModule, setSelectedModule] = React.useState<number>();

    const formatDate = (dateISOString: any) => {
        const date = new Date(
            DateTime.fromISO(dateISOString as any).toJSDate(),
        );
        const month = date.getMonth() + 1;
        const day = date.getDate();
        const year = date.getFullYear();
        return `${month.toString().padStart(2, '0')}/${day
            .toString()
            .padStart(2, '0')}/${year}`;
    };

    const getModules = (classID: number) => {
        return AuthPost(
            '/classes/getclassmodules',
            { classID: classID },
            {
                'x-access-token': globalState.authToken,
                canCache: false,
            },
        )
            .then((res: any) => {
                if (res?.status === 200) {
                    return res?.data;
                } else {
                    return [];
                }
            })
            .catch((err: any) => {
                toast.error(err.toString());
            });
    };

    const lateOval = () => {
        return (
            <div
                style={{
                    backgroundColor: 'red',
                    borderRadius: '50%', // Makes it oval
                    padding: '2px 6px 4px 6px', // Adjust padding to fit the text
                    display: 'inline-block', // To wrap the text tightly
                    color: 'white', // Text color
                }}
            >
                Late
            </div>
        );
    };

    const todoAssignmentsReadyToGrade = (classData: any) => {
        const today = DateTime.now();
        const dueDate = DateTime.fromISO(classData?.classData?.endDate);

        return (
            <div style={{ fontSize: '14px', paddingBottom: '10px' }}>
                <div
                    className={'Nav-Link'}
                    onClick={() => setSelectedClass(classData?.classID)}
                >
                    {classData?.classData?.className} - Evaluate Assignments
                </div>
                <div>
                    {formatDate(classData?.classData?.endDate)}{' '}
                    {dueDate < today ? lateOval() : <></>}
                </div>
            </div>
        );
    };
    const todoModules = (moduleData: any) => {
        return (
            <div style={{ fontSize: '14px', paddingBottom: '10px' }}>
                <div
                    className={'Nav-Link'}
                    onClick={() => {
                        setSelectedClass(moduleData?.classID);
                        setSelectedModule(moduleData?.id);
                    }}
                >
                    {moduleData.title} - Active Lesson
                </div>
                <div>{formatDate(moduleData.endDate)}</div>
            </div>
        );
    };

    React.useEffect(() => {
        setLoading(true);
        AuthPost(
            '/userclass/getuserclasses',
            {
                userID: globalState.user?.id,
            },
            {
                'x-access-token': globalState.authToken,
                canCache: false,
            },
        )
            .then((res) => {
                return res?.data?.map((cls: any) => {
                    return cls.classID;
                });
            })
            .then((classIDs) => {
                if (
                    globalState.user?.type?.includes('Instructor') ||
                    globalState.user?.type?.includes('APTAdmin')
                ) {
                    const mappedClasses = classIDs?.map((classID: any) => {
                        return AuthPost(
                            '/gradebook/getclassgradedstatus',
                            {
                                classID: classID,
                            },
                            {
                                'x-access-token': globalState?.authToken || '',
                                canCache: true,
                            },
                        )
                            .then((res) => {
                                if (
                                    res?.status === 200 ||
                                    res?.status === 204
                                ) {
                                    return res.data;
                                } else {
                                    return [];
                                }
                            })
                            .catch((err) => {
                                console.error(err.toString());
                                toast.error(err.toString());
                            });
                    });
                    return mappedClasses;
                } else {
                    return classIDs;
                }
            })
            .then((promArray) => {
                if (
                    globalState.user?.type?.includes('Instructor') ||
                    globalState.user?.type?.includes('APTAdmin')
                ) {
                    Promise.all(promArray)
                        .then((resolvedArray: any) => {
                            const filteredArray = resolvedArray
                                .filter((item: any) => item?.length)
                                .map((classGBs: any) => {
                                    return {
                                        count: classGBs?.length,
                                        classID: classGBs?.[0]?.classID,
                                    };
                                });
                            const mappedFilteredArray: any = filteredArray?.map(
                                (countItem: any) => {
                                    return AuthPost(
                                        '/class/getclass',
                                        {
                                            classID: countItem.classID,
                                        },
                                        {
                                            'x-access-token':
                                                globalState?.authToken || '',
                                            canCache: true,
                                        },
                                    )
                                        .then((res) => {
                                            if (
                                                res?.status === 200 ||
                                                res?.status === 204
                                            ) {
                                                return {
                                                    count: countItem?.count,
                                                    classID: countItem?.classID,
                                                    classData: res.data?.[0],
                                                };
                                            } else {
                                                return [];
                                            }
                                        })
                                        .catch((err) => {
                                            console.error(err.toString());
                                            toast.error(err.toString());
                                        });
                                },
                            );
                            return mappedFilteredArray;
                        })
                        .then((promArray2) => {
                            Promise.all(promArray2).then(
                                (resolvedArray: any) => {
                                    const todoItems = resolvedArray
                                        ?.filter(
                                            (cls: any) =>
                                                cls?.classData?.instructorID ===
                                                globalState?.user?.id,
                                        )
                                        ?.map((item: any) => {
                                            return todoAssignmentsReadyToGrade(
                                                item,
                                            );
                                        });
                                    setTodo(todoItems);
                                },
                            );
                        });
                } else {
                    const getMods = promArray?.map((cid: any) => {
                        return AuthPost(
                            '/classdetail/getclassdetail',
                            { classID: cid },
                            {
                                'x-access-token': globalState.authToken,
                                canCache: true,
                            },
                        ).then((res) => {
                            return getModules(cid).then((mods) => {
                                return mods.flat().map((mod: any) => {
                                    const classDetail = res.data?.filter(
                                        (detail: any) => {
                                            return mod?.id === detail?.moduleID;
                                        },
                                    )[0];
                                    return {
                                        ...mod,
                                        classID: cid,
                                        endDate: classDetail.endDate,
                                    };
                                });
                            });
                        });
                    });
                    Promise.all(getMods)
                        .then((resolvedArray: any) => {
                            const todoItems = resolvedArray
                                ?.flat()
                                ?.map((item: any) => {
                                    return todoModules(item);
                                });
                            setTodo(todoItems);
                        })
                        .catch((err: any) => {
                            console.error(err.toString());
                            toast.error(err.toString());
                        });
                }
            })
            .then(() => setLoading(false))
            .catch((err) => {
                toast.error(err.toString());
                setLoading(false);
            });
    }, []);

    const dashboardHeader = (
        <Grid container sx={{ margin: 0 }} spacing={2}>
            <Grid
                sx={{
                    textAlign: 'left',
                }}
                md={8}
                xs={6}
            >
                <h3>
                    <Typography
                        style={{
                            paddingLeft: '16px',
                            fontFamily: 'sans-serif',
                            fontSize: '24px',
                            fontWeight: 'bold',
                            color: '#3d5170',
                        }}
                    >
                        {'Dashboard - My Classes'}
                    </Typography>
                </h3>
            </Grid>
            <Grid
                sx={{
                    textAlign: 'right',
                    alignContent: 'center',
                    paddingRight: '32px',
                }}
                xs={6}
                md={4}
            >
                <img src={ITALogo01} width={'100px'} />
            </Grid>
        </Grid>
    );

    return (
        <ThemeProvider theme={defaultTheme}>
            {/* Uncomment and drop email html in to view and edit live in *local development only* DO NOT leave this uncommented
                <div
                dangerouslySetInnerHTML={{
                    __html: testHtml(
                        'Some Title Here',
                        DateTime.now(),
                    ),
                }}
            /> */}
            {!selectedClass && !selectedModule ? (
                <Box
                    sx={{
                        padding: '20px 0px 0px 0px',
                        margin: '10px 15px 10px 0px',
                        maxWidth: '100vp',
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <Grid container sx={{ margin: 0 }} spacing={2}>
                        <Grid
                            // sx={{
                            //     padding: '8px',
                            //     margin: 'auto',
                            // }}
                            xs={10}
                        >
                            {/* <Card
                                sx={{
                                    maxWidth: '100%',
                                    padding: '20px',
                                    textAlign: 'center',
                                }}
                            >
                                
                                <hr /> */}
                            <Classes
                                headerComponent={dashboardHeader}
                                landingPage={true}
                                classSelected={setSelectedClass}
                            />
                            {/* </Card> */}
                        </Grid>
                        <Grid
                            sx={{
                                padding: '10px',
                                // margin: 'auto',
                            }}
                            xs={2}
                        >
                            <Card
                                sx={{
                                    maxWidth: '100%',
                                    padding: '10px',
                                    height: 'calc(100% - 20px)',
                                }}
                            >
                                <h3>
                                    <Typography
                                        style={{
                                            paddingTop: '4px',
                                            paddingBottom: '4px',
                                            textAlign: 'center',
                                            fontFamily: 'sans-serif',
                                            fontSize: '24px',
                                            fontWeight: 'bold',
                                            color: '#3d5170',
                                        }}
                                    >
                                        {'To Do'}
                                    </Typography>
                                </h3>
                                <hr />
                                {!loading ? todo : <></>}
                            </Card>
                        </Grid>
                    </Grid>
                </Box>
            ) : (
                <>
                    {!loading ? (
                        <Classes
                            selectClass={selectedClass}
                            selectedModule={selectedModule}
                            classSelected={setSelectedClass}
                        />
                    ) : (
                        <></>
                    )}
                </>
            )}
        </ThemeProvider>
    );
};
