import * as React from 'react';
import Box from '@mui/material/Box';
import { Button, Card, Grid, TextField } from '@mui/material';
import { EditModulePageModal } from '../modules/EditModulePageModal';
import {
    ApiOrganization,
    Class,
    Module,
    ModulePage,
    Question,
} from '../interfaces/Interfaces';
import { AuthGet, AuthDelete, AuthPost } from '../utilities/Routing/Requests';
import { useGlobalStore } from '../utilities/GlobalState';
import { toast } from 'react-toastify';
import { ConfirmationModal } from '../utilities/Modals/ConfirmationModal';
import { scrollToCurrentHeadingAnchor } from '../utilities/RichTextEditor/HeadingWithAnchor';
import { RichTextEditorRef } from 'mui-tiptap';
import { Editor } from '@tiptap/core';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { ClassPreviewCard } from './ClassPreviewCard';
import { ClassDetails } from './ClassDetails';
import { CreateClassModal } from './CreateClassesModal';
import { AssignmentsTab } from '../courses/AssignmentsTab';
import { AddModuleModal } from './AddModuleModal';
import { GradeBookModal } from './GradeBookModal';
import { AxiosResponse } from 'axios';
import { defaultTheme } from '../utilities/Utilites';
import SliderSwitch from '../utilities/SliderSwitch';
// import { CreateEditStudentModal } from './CreateEditStudentModal';

interface Props {
    landingPage?: boolean;
    selectClass?: number;
    selectedModule?: number;
    headerComponent?: any;
    showClassJoin?: boolean;
    classSelected?: (cls?: number) => void;
}
const Classes = ({
    landingPage,
    selectClass,
    selectedModule,
    headerComponent,
    showClassJoin,
    classSelected,
}: Props) => {
    const globalState = useGlobalStore((state) => state);
    const rteRef = React.useRef<RichTextEditorRef | any>(null);
    const [cardView, setCardView] = React.useState(true);
    const [createModulePageOpen, setCreateModulePageOpen] =
        React.useState(false);
    const [joinCode, setJoinCode] = React.useState<string>();
    // const [editStudentModalIsOpen, setEditStudentModalIsOpen] =
    //     React.useState(false);
    const [createClassOpen, setCreateClassOpen] = React.useState(false);
    const [addModuleOpen, setAddModuleOpen] = React.useState(false);
    const [gradeBookOpen, setGradeBookOpen] = React.useState(false);
    const [selectedStudent, setSelectedStudent] = React.useState<
        number | undefined
    >();
    // const [value, setValue] = React.useState(0);
    const [selectedClass, setSelectedClass] = React.useState<
        number | undefined
    >();
    const [modulesForSelectedClass, setModulesForSelectedClass] =
        React.useState<Array<Module>>([]);
    const [moduleForGradebook, setModuleForGradebook] =
        React.useState<number>();
    const [userClassIds, setUserClassIds] = React.useState<Array<number>>([]);
    const [classes, setClasses] = React.useState<Array<Class>>([
        // {
        //     id: 8,
        //     title: 'Round 3	Fight!',
        //     description: 'test',
        //     pages: '1,2',
        //     image: 'https://imageslms.blob.core.windows.net/images-lms/1712157337520-create',
        // },
    ]);
    const [viewModuleDetails, setViewModuleDetails] = React.useState(0);
    // TODO: class id is being added here sometimes?? ^
    const [viewModuleId, setViewModuleId] = React.useState(0);
    const [classCount, setClassCount] = React.useState(0);
    const [page, setPage] = React.useState<ModulePage | undefined>();
    const [showDelete, setShowDelete] = React.useState<boolean>(false);
    const [showDeleteModule, setShowDeleteModule] =
        React.useState<boolean>(false);
    const [moduleDeleteId, setModuleDeleteId] = React.useState<
        number | undefined
    >();
    const [pageDeleteId, setPageDeleteId] = React.useState<
        number | undefined
    >();
    const [questions, setQuestions] = React.useState<Array<Question>>([]);
    // const [currentPage, setCurrentPage] = React.useState<number>(0);
    const [newPageType, setNewPageType] = React.useState<string>('');
    const [modules, setModules] = React.useState<Array<Module>>([]);
    const [pages, setPages] = React.useState<Array<ModulePage>>([]);
    const [disableForward, setDisableForward] = React.useState<boolean>(false);
    const [toggleShowStudentGradebook, setToggleShowStudentGradebook] =
        React.useState<boolean>(false);
    // const [selectedOrganization, setSelectedOrganization] =
    //     React.useState<ApiOrganization>();
    const [organizations, setOrganizations] = React.useState<
        Array<ApiOrganization>
    >([]);
    // const [instructors, setInstructors] = React.useState<
    //     Array<OrganizationUser>
    // >([]);
    const [loading, setLoading] = React.useState<boolean>(false);

    const getOrganizations = async () => {
        await AuthGet('/organization/orglist', {
            'x-access-token': globalState?.authToken || '',
            canCache: true,
        })
            .then((res: AxiosResponse<Array<ApiOrganization>>) => {
                const orgList = res?.data?.filter((orgs) => {
                    return orgs?.id?.toString() === globalState?.user?.orgId;
                });
                if (!globalState?.user?.type?.includes('APTAdmin')) {
                    setOrganizations(orgList);
                    // if (selectedOrg) {
                    //     const organization = orgList.filter(
                    //         (org) => org.id === selectedOrg,
                    //     )[0];
                    //     // setSelectedOrganization(organization);
                    // }

                    return orgList;
                } else {
                    // if (selectedOrg) {
                    //     const organization = res?.data.filter(
                    //         (org) => org.id === selectedOrg,
                    //     )[0];
                    //     // setSelectedOrganization(organization);
                    // }
                    return res?.data;
                }
            })
            .then((data) => {
                setOrganizations(data);
            })
            .catch((err) => {
                toast.error(err.toString());
            });
    };

    React.useEffect(() => {
        getOrganizations();
    }, []);

    const urlHashChange = () => {
        scrollToCurrentHeadingAnchor(
            rteRef.current.editor as Editor,
            globalState.setCurrentPage,
            globalState?.currentPage || 0,
        );
    };

    React.useEffect(() => {
        if (rteRef?.current?.editor) {
            urlHashChange();
        }
    }, [window.location.hash]);

    const getClasses = async () => {
        if (globalState?.user?.type?.includes('OrganizationAdmin')) {
            await AuthPost(
                '/class/getorgclasses',
                {
                    orgID: globalState.user?.orgId,
                },
                {
                    'x-access-token': globalState.authToken,
                    canCache: false,
                },
            )
                .then((res: any) => {
                    return res?.data?.map((id: any) =>
                        AuthPost(
                            '/class/getclass',
                            {
                                classID: id.classID,
                            },
                            {
                                'x-access-token': globalState.authToken,
                                canCache: false,
                            },
                        )
                            .then((res1) => {
                                if (res1?.status === 200) {
                                    return res1.data[0];
                                }
                            })
                            .catch((err) => {
                                toast.error(err.toString());
                            }),
                    );
                })
                .then(async (promArray) => {
                    await AuthPost(
                        '/userclass/getuserclasses',
                        {
                            userID: globalState.user?.id,
                        },
                        {
                            'x-access-token': globalState.authToken,
                            canCache: false,
                        },
                    )
                        .then((res) => {
                            const classIDS = res?.data?.map((cls: any) => {
                                return cls.classID;
                            });

                            setUserClassIds(classIDS);
                            return classIDS;
                        })
                        .then((allIDs) => {
                            Promise.all(promArray).then(
                                (resolvedArray: any) => {
                                    const missingIds = allIDs.filter(
                                        (id: number) =>
                                            !resolvedArray.some(
                                                (obj: any) =>
                                                    obj.classID === id,
                                            ),
                                    );
                                    if (missingIds?.length) {
                                        const getMissingClasses =
                                            missingIds?.map((id: any) =>
                                                AuthPost(
                                                    '/class/getclass',
                                                    {
                                                        classID: id,
                                                    },
                                                    {
                                                        'x-access-token':
                                                            globalState.authToken,
                                                        canCache: false,
                                                    },
                                                )
                                                    .then((res1) => {
                                                        if (
                                                            res1?.status === 200
                                                        ) {
                                                            return res1.data[0];
                                                        }
                                                    })
                                                    .catch((err) => {
                                                        toast.error(
                                                            err.toString(),
                                                        );
                                                    }),
                                            );
                                        Promise.all(getMissingClasses).then(
                                            (resolvedMissingArray: any) => {
                                                const resMissingArray: any =
                                                    landingPage
                                                        ? resolvedMissingArray?.filter(
                                                              (
                                                                  userClass: Class,
                                                              ) =>
                                                                  userClass?.status ===
                                                                  'active',
                                                          )
                                                        : resolvedMissingArray;
                                                const newClassArray: Array<any> =
                                                    resolvedArray.concat(
                                                        resMissingArray,
                                                    );
                                                setClassCount(
                                                    newClassArray?.length,
                                                );
                                                setClasses(newClassArray);
                                            },
                                        );
                                    } else {
                                        const resArray: any = landingPage
                                            ? resolvedArray?.filter(
                                                  (userClass: Class) =>
                                                      userClass?.status ===
                                                      'active',
                                              )
                                            : resolvedArray;
                                        setClassCount(resArray?.length);
                                        setClasses(resArray);
                                    }
                                },
                            );
                        })
                        .catch((err) => {
                            toast.error(err.toString());
                        });
                })
                .catch((err) => {
                    toast.error(err.toString());
                });
        } else if (globalState?.user?.type?.includes('APTAdmin')) {
            await AuthGet('/class/getallclasses', {
                'x-access-token': globalState.authToken,
                canCache: false,
            })
                .then((res: any) => {
                    return res?.data?.map((id: any) =>
                        AuthPost(
                            '/class/getclass',
                            {
                                classID: id.classID,
                            },
                            {
                                'x-access-token': globalState.authToken,
                                canCache: false,
                            },
                        )
                            .then((res1) => {
                                if (res1?.status === 200) {
                                    return res1.data[0];
                                }
                            })
                            .catch((err) => {
                                toast.error(err.toString());
                            }),
                    );
                })
                .then(async (promArray) => {
                    await AuthPost(
                        '/userclass/getuserclasses',
                        {
                            userID: globalState.user?.id,
                        },
                        {
                            'x-access-token': globalState.authToken,
                            canCache: false,
                        },
                    )
                        .then((res) => {
                            setUserClassIds(
                                res?.data?.map((cls: any) => {
                                    return cls.classID;
                                }),
                            );
                            return res?.data;
                        })
                        .catch((err) => {
                            toast.error(err.toString());
                        });
                    Promise.all(promArray).then((resolvedArray: any) => {
                        const resArray: any = landingPage
                            ? resolvedArray?.filter(
                                  (userClass: Class) =>
                                      userClass?.status === 'active',
                              )
                            : resolvedArray;
                        setClassCount(resArray?.length);
                        setClasses(resArray);
                    });
                })
                .catch((err) => {
                    toast.error(err.toString());
                });
        } else {
            await AuthPost(
                '/userclass/getuserclasses',
                {
                    userID: globalState.user?.id,
                },
                {
                    'x-access-token': globalState.authToken,
                    canCache: false,
                },
            )
                .then((res) => {
                    const mappedIDS: Array<any> = res?.data?.map((cls: any) => {
                        return cls.classID;
                    });
                    setUserClassIds(mappedIDS);
                    return res?.data;
                })
                .then((classIds) => {
                    return classIds?.map((id: any) =>
                        AuthPost(
                            '/class/getclass',
                            {
                                classID: id.classID,
                            },
                            {
                                'x-access-token': globalState.authToken,
                                canCache: false,
                            },
                        )
                            .then((res) => {
                                if (res?.status === 200) {
                                    return res.data[0];
                                }
                            })
                            .catch((err) => {
                                toast.error(err.toString());
                            }),
                    );
                })
                .then((promArray) => {
                    Promise.all(promArray).then((resolvedArray: any) => {
                        const resArray: any = landingPage
                            ? resolvedArray?.filter(
                                  (userClass: Class) =>
                                      userClass?.status === 'active',
                              )
                            : resolvedArray;
                        setClassCount(resArray?.length);
                        setClasses(resArray);
                    });
                })
                .catch((err) => {
                    toast.error(err.toString());
                });
        }
    };

    const getModules = async () => {
        let data: Array<any> = [];
        await AuthGet('/organization/modules', {
            'x-access-token': globalState.authToken,
            canCache: false,
        })
            .then((res) => {
                if (res?.status === 200) {
                    data = globalState?.user?.type?.includes('APTAdmin')
                        ? res?.data
                        : res?.data?.filter(
                              (mod: any) =>
                                  mod?.active && mod?.publishDate?.length,
                          );
                    setModules(data);
                } else {
                    return [];
                }
            })
            .catch((err) => {
                toast.error(err.toString());
            });
        return data;
    };

    const getModulePages = (moduleId: number) => {
        let data: Array<any> = [];
        return AuthPost(
            '/organization/modulepages',
            { moduleId: moduleId },
            {
                'x-access-token': globalState.authToken,
                canCache: true,
            },
        )
            .then((res) => {
                if (res?.status === 200) {
                    data = res?.data;
                    setPages(
                        data?.sort(
                            (a: any, b: any) =>
                                Number(a.pageNumber) - Number(b.pageNumber),
                        ),
                    );
                    return res.data;
                } else {
                    return [];
                }
            })
            .catch((err) => {
                toast.error(err.toString());
            });
    };

    const getClassModules = async (classID: number, mods?: Array<Module>) => {
        let data: Array<any> = [];
        setModulesForSelectedClass([]);
        setLoading(true);
        await AuthPost(
            '/classdetail/getclassdetail',
            { classID: classID },
            {
                'x-access-token': globalState.authToken,
                canCache: true,
            },
        )
            .then((res) => {
                if (res?.status === 200) {
                    data = res?.data;
                    const classMods: Array<Module> = [];
                    data.forEach((classModuleDetail) => {
                        if (mods) {
                            return mods.filter((mod) => {
                                if (
                                    globalState?.user?.type?.includes(
                                        'APTAdmin',
                                    ) &&
                                    mod.id === classModuleDetail.moduleID
                                ) {
                                    mod.startDate = classModuleDetail.startDate;
                                    mod.endDate = classModuleDetail.endDate;
                                    classMods.push(mod);
                                } else if (
                                    mod.id === classModuleDetail.moduleID &&
                                    mod.active
                                ) {
                                    mod.startDate = classModuleDetail.startDate;
                                    mod.endDate = classModuleDetail.endDate;
                                    classMods.push(mod);
                                }
                                return mod.id === classModuleDetail.moduleID;
                            })[0];
                        } else {
                            return modules.filter((mod) => {
                                if (
                                    globalState?.user?.type?.includes(
                                        'APTAdmin',
                                    ) &&
                                    mod.id === classModuleDetail.moduleID
                                ) {
                                    mod.startDate = classModuleDetail.startDate;
                                    mod.endDate = classModuleDetail.endDate;
                                    classMods.push(mod);
                                } else if (
                                    mod.id === classModuleDetail.moduleID &&
                                    mod.active
                                ) {
                                    mod.startDate = classModuleDetail.startDate;
                                    mod.endDate = classModuleDetail.endDate;
                                    classMods.push(mod);
                                }
                                return mod.id === classModuleDetail.moduleID;
                            })[0];
                        }
                    });
                    // setModules(classMods);
                    setModulesForSelectedClass(classMods);
                    setLoading(false);
                    return res.data;
                } else {
                    setLoading(false);
                    return [];
                }
            })
            .catch((err) => {
                setLoading(false);
                toast.error(err.toString());
            });
    };

    React.useEffect(() => {
        getModules()
            .then((mods) => {
                getClasses();
                return mods;
            })
            .then((mods1) => {
                if (selectClass !== selectedClass) {
                    setSelectedClass(selectClass);
                }
                if (selectClass || selectedClass) {
                    const classSelection = selectClass || selectedClass || 0;
                    getClassModules(classSelection, mods1).then(() => {
                        if (selectedModule) {
                            getModulePages(selectedModule || viewModuleId).then(
                                () => {
                                    setViewModuleId(selectedModule);
                                    setViewModuleDetails(
                                        selectClass || selectedClass || 0,
                                    );
                                },
                            );
                        } else {
                            setViewModuleDetails(
                                selectClass || selectedClass || 0,
                            );
                        }
                    });
                }
            });
    }, []);

    const setNewCurrentPage = (newPage?: number): void => {
        // setCurrentPage(newPage);
        globalState.setCurrentPage(newPage || 0);
    };

    const deletePage = () => {
        return AuthDelete(`/organization/deletepage/${pageDeleteId}`, {
            'x-access-token': globalState.authToken,
            canCache: true,
        })
            .then((res) => {
                setShowDelete(false);
                return res;
            })
            .catch((err) => {
                toast.error(err.toString());
            });
    };

    const deleteModule = () => {
        return AuthDelete(`/organization/deletemodule/${moduleDeleteId}`, {
            'x-access-token': globalState.authToken,
            canCache: true,
        })
            .then((res) => {
                setViewModuleDetails(0);
                setViewModuleId(0);
                getClasses();
                setShowDeleteModule(false);
                return res;
            })
            .catch((err) => {
                toast.error(err.toString());
            });
    };

    const handleToggleAddModule = (open?: boolean) => {
        const current = addModuleOpen;
        setAddModuleOpen(open || !current);
        if (current && selectedClass) {
            getClassModules(selectedClass);
        }
    };

    const handleToggleGradeBook = (studentID?: number, moduleID?: number) => {
        if (moduleID) {
            setModuleForGradebook(moduleID);
        }
        setSelectedStudent(studentID);
        if (!studentID && !moduleID) {
            getOrganizations();
            const classID = selectClass || selectedClass;
            if (classID) {
                getClassModules(classID);
            }
            getModules();
        }
        const current = gradeBookOpen;
        setGradeBookOpen(!current);
    };

    const handleToggleCreateClass = (refresh?: boolean) => {
        const current = createClassOpen;
        setCreateClassOpen(!current);
        if (refresh) {
            getClasses();
        }
    };

    const handleToggleCreateModulePage = (edit?: boolean, newType?: string) => {
        if (!edit) {
            setPage(undefined);
        }
        if (newType) {
            setNewPageType(newType);
        }
        const current = createModulePageOpen;
        setCreateModulePageOpen(!current);
    };

    const toggleDeletePageModal = (
        id: number | undefined,
        refresh?: boolean,
    ) => {
        setPageDeleteId(id);
        const current = showDelete;
        setShowDelete(!current);
        if (refresh) {
            getModulePages(viewModuleId);
        }
    };

    const toggleDeleteModuleModal = (id: number | undefined) => {
        setModuleDeleteId(id);
        const current = showDeleteModule;
        setShowDeleteModule(!current);
    };

    // const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    //     setValue(newValue); SBOGzAy student@apt.com instructor@apt.com
    // };

    const addStudentToClass = () => {
        return AuthPost(
            '/userclass/adduserclass',
            { userID: globalState.user?.id, classID: joinCode },
            {
                'x-access-token': globalState.authToken,
                canCache: true,
            },
        )
            .then((res) => {
                if (res?.status === 200) {
                    getClasses();
                    return res.data;
                } else {
                    return [];
                }
            })
            .catch((err) => {
                toast.error(err.toString());
            });
    };

    const toggleClassStatus = (status: string) => {
        return AuthPost(
            '/class/classstatus',
            { status: status, classID: selectedClass },
            {
                'x-access-token': globalState.authToken,
                canCache: true,
            },
        )
            .then((res) => {
                if (res?.status === 200) {
                    getClasses();
                    return res.data;
                } else {
                    return res?.status;
                }
            })
            .catch((err) => {
                toast.error(err.toString());
            });
    };

    React.useEffect(() => {
        if (modules?.length && !selectClass) {
            getClasses();
        }
    }, [modules]);

    const isStudent = (classId?: number) => {
        if (globalState.user?.type === 'Student') {
            return true;
        } else if (classId && userClassIds.includes(classId)) {
            return true;
        } else {
            return false;
        }
    };

    const studentClassesMap = () => {
        return classes.map((classy: any) => {
            return (
                <ClassPreviewCard
                    key={classy.classID}
                    handleToggleModulePage={() => {
                        setSelectedClass(classy.classID);
                        if (classSelected) {
                            classSelected(classy.classID);
                        }
                        getClassModules(classy.classID);
                    }}
                    module={classy}
                />
            );
        });
    };
    const mappedClasses = () => {
        return classes.map((classy: any) => {
            // if (classy.type === 'Student') {
            //     return (
            //         <ClassPreviewCard
            //             key={classy.classID}
            //             handleToggleModulePage={() => {
            //                 setSelectedClass(
            //                     classy.classID,
            //                 );
            //                 getClassModules(
            //                     classy.classID,
            //                 );
            //             }}
            //             module={classy}
            //         />
            //     );
            // } else {

            if (
                userClassIds?.includes(classy?.id) &&
                classy?.instructorID !== Number(globalState?.user?.id)
            ) {
                return (
                    <ClassPreviewCard
                        key={classy.classID}
                        handleToggleModulePage={() => {
                            setSelectedClass(classy.classID);
                            if (classSelected) {
                                classSelected(classy.classID);
                            }
                            getClassModules(classy.classID);
                        }}
                        module={classy}
                    />
                );
            } else {
                return (
                    <ClassPreviewCard
                        key={classy.classID}
                        handleToggleModulePage={() => {
                            getClassModules(classy.classID);
                            setSelectedClass(classy.classID);
                            if (classSelected) {
                                classSelected(classy.classID);
                            }
                            setViewModuleDetails(classy.classID);
                        }}
                        module={classy}
                    />
                );
            }
            // }
        });
    };

    const toggleShowStudentGradebookModal = (
        studentID?: number,
        moduleID?: number,
    ) => {
        if (moduleID) {
            setModuleForGradebook(moduleID);
        }
        setSelectedStudent(
            studentID || Number(globalState?.user?.id) || undefined,
        );
        if (!studentID && !moduleID) {
            getModules();
            const classID = selectClass || selectedClass;
            if (classID) {
                getClassModules(classID);
            }
            getOrganizations();
        }
        const current = toggleShowStudentGradebook;
        setToggleShowStudentGradebook(!current);
    };

    return (
        <Box
            sx={{
                // padding: '20px',
                // margin: '10px 10px 10px 10px',
                maxWidth: '100vp',
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            {/* {CreateEditStudentModal({
                        open: editStudentModalIsOpen,
                        handleClose: () => setEditStudentModalIsOpen(false),
                        organization: selectedOrganization,
                        authToken: globalState.authToken,
                        selectedInstructor: selectedInstructor,
                        selectedStudent: selectedStudent,
                    })} */}
            {!showDelete ? (
                <></>
            ) : (
                <ConfirmationModal
                    open={showDelete}
                    handleClose={toggleDeletePageModal}
                    title={'Confirm Page Deletion'}
                    confirmationText={
                        'Are you sure you want to permanently DELETE this page? This action is irreversible.'
                    }
                    confirmationButtonText={'DELETE'}
                    confirmationButtonAction={deletePage}
                />
            )}
            {!createClassOpen ? (
                <></>
            ) : (
                <CreateClassModal
                    open={createClassOpen}
                    handleClose={handleToggleCreateClass}
                    organizations={organizations}
                />
            )}
            {!addModuleOpen && selectedClass ? (
                <></>
            ) : (
                <AddModuleModal
                    open={addModuleOpen}
                    handleClose={handleToggleAddModule}
                    classID={selectedClass || 0}
                    modules={modules}
                />
            )}
            {!gradeBookOpen && selectedClass && selectedClass > 0 ? (
                <></>
            ) : (
                <GradeBookModal
                    open={gradeBookOpen}
                    classID={selectedClass || 0}
                    handleToggleGradeBook={handleToggleGradeBook}
                    modules={modulesForSelectedClass}
                    studentID={selectedStudent}
                    userType={globalState?.user?.type || 'Student'}
                    selectedModule={moduleForGradebook}
                />
            )}
            {!showDeleteModule ? (
                <></>
            ) : (
                <ConfirmationModal
                    open={showDeleteModule}
                    handleClose={toggleDeleteModuleModal}
                    title={'Confirm Module Deletion'}
                    confirmationText={
                        'Are you sure you want to permanently DELETE this module? All associated pages will be deleted as well. This action is irreversible.'
                    }
                    confirmationButtonText={'DELETE'}
                    confirmationButtonAction={deleteModule}
                />
            )}
            <EditModulePageModal
                getModulePages={getModulePages}
                open={createModulePageOpen}
                handleClose={handleToggleCreateModulePage}
                moduleId={viewModuleId}
                page={page}
                setPage={setPage}
                setCreateModulePageOpen={setCreateModulePageOpen}
                questions={questions}
                setQuestions={setQuestions}
                newType={newPageType}
                rteRef={rteRef}
                urlHashChange={urlHashChange}
            />

            <Card
                sx={{
                    maxWidth: '100%',
                    minHeight: 'calc(100vh - 120px)',
                    margin: landingPage ? '10px' : '40px 10px 10px 10px',
                    // padding: '10px',
                    position: 'relative',
                }}
            >
                {/* {!selectedClass && !landingPage && showClassJoin ? ( */}
                {!selectedClass && !landingPage ? (
                    // <Card
                    //     sx={{
                    //         maxWidth: '100%',
                    //         padding: '20px',
                    //         margin: '0 0 20px 0',
                    //     }}
                    // >
                    <div style={{ padding: '10px' }}>
                        <TextField
                            // margin="normal"
                            fullWidth
                            id="code"
                            label="Class Join Code"
                            name="code"
                            autoFocus
                            onChange={(
                                e: React.ChangeEvent<
                                    HTMLInputElement | HTMLTextAreaElement
                                >,
                            ) => {
                                setJoinCode(e?.currentTarget?.value);
                            }}
                        />
                        <Button
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                            onClick={addStudentToClass}
                        >
                            JOIN
                        </Button>
                    </div>
                ) : (
                    // </Card>
                    <></>
                )}
                {headerComponent ? headerComponent : <></>}
                {(headerComponent || showClassJoin) && landingPage ? (
                    <hr />
                ) : (
                    <></>
                )}
                {!toggleShowStudentGradebook &&
                selectedClass &&
                selectedClass > 0 ? (
                    <></>
                ) : (
                    <GradeBookModal
                        open={toggleShowStudentGradebook}
                        classID={selectedClass}
                        handleToggleGradeBook={toggleShowStudentGradebookModal}
                        modules={modulesForSelectedClass}
                        studentID={Number(globalState?.user?.id)}
                        userType={globalState?.user?.type || 'Student'}
                        selectedModule={moduleForGradebook}
                    />
                )}
                {globalState.user?.type === 'Student' ? (
                    <Grid
                        container
                        // spacing={1}
                        // key={'slider-root-grid-container'}
                    >
                        {(!selectedClass || selectedClass < 1) &&
                        classes?.length === classCount ? (
                            studentClassesMap()
                        ) : (
                            <></>
                        )}
                        {selectedClass && selectedClass > 0 ? (
                            <>
                                {!viewModuleId || viewModuleId === 0 ? (
                                    <Grid
                                        container
                                        // spacing={1}
                                        // key={'slider-root-grid-container'}
                                    >
                                        <Grid
                                            xs={8}
                                            sx={{
                                                paddingTop: '10px',
                                                paddingLeft: '20px',
                                                paddingRight: '20px',
                                            }}
                                        >
                                            <></>
                                        </Grid>
                                        <Grid
                                            xs={4}
                                            sx={{
                                                paddingTop: '10px',
                                                paddingLeft: '20px',
                                                paddingRight: '20px',
                                                textAlignLast: 'right',
                                            }}
                                        >
                                            Card
                                            <SliderSwitch
                                                sx={{ m: 1 }}
                                                value={cardView}
                                                onChange={() =>
                                                    setCardView(!cardView)
                                                }
                                            />
                                            Table
                                        </Grid>
                                    </Grid>
                                ) : (
                                    <></>
                                )}
                                <AssignmentsTab
                                    classTitle={
                                        classes?.filter(
                                            (cls: any) =>
                                                cls?.id === selectedClass,
                                        )[0]?.className
                                    }
                                    questions={questions}
                                    modules={modulesForSelectedClass}
                                    pages={pages}
                                    currentPage={globalState?.currentPage || 0}
                                    setCurrentPage={setNewCurrentPage}
                                    getModulePages={getModulePages}
                                    setPages={setPages}
                                    disableForward={disableForward}
                                    setDisableForward={setDisableForward}
                                    classID={selectedClass}
                                    instructorID={Number(
                                        classes?.filter(
                                            (cls: any) =>
                                                cls?.id === selectedClass,
                                        )[0]?.instructorID,
                                    )}
                                    toggleBack={() => {
                                        setViewModuleDetails(0);
                                        setViewModuleId(0);
                                        setSelectedClass(undefined);
                                        if (classSelected) {
                                            classSelected(undefined);
                                        }
                                    }}
                                    showGradebook={
                                        toggleShowStudentGradebookModal
                                    }
                                    cardView={cardView}
                                    setViewModuleDetailsParent={(
                                        module: number,
                                    ) => {
                                        setViewModuleId(module);
                                        setViewModuleDetails(module);
                                    }}
                                    selectedModule={
                                        viewModuleId || selectedModule
                                    }
                                />
                            </>
                        ) : (
                            <></>
                        )}
                    </Grid>
                ) : (
                    <>
                        <Grid
                            container
                            // spacing={1}
                            // key={'slider-root-grid-container'}
                        >
                            {viewModuleDetails === 0 && !landingPage ? (
                                <Card
                                    onClick={() =>
                                        handleToggleCreateClass(false)
                                    }
                                    sx={{
                                        width: '300px',
                                        height: '300px',
                                        margin: '10px',
                                        textAlign: 'center',
                                        boxShadow:
                                            '0px 2px 11px -1px rgba(0,0,0,4.8),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)',
                                    }}
                                >
                                    <AddCircleIcon
                                        key={'blog-grid-slider-add-icon'}
                                        sx={{
                                            paddingTop: '65px',
                                            width: '150px',
                                            height: '150px',
                                            color: `${defaultTheme.palette.primary.main}`,
                                        }}
                                    />
                                </Card>
                            ) : (
                                <></>
                            )}
                            <>
                                {viewModuleDetails === 0 &&
                                classes?.length === classCount ? (
                                    mappedClasses()
                                ) : (
                                    <></>
                                )}
                            </>
                        </Grid>
                        {viewModuleDetails !== 0 &&
                        classes?.length &&
                        classes?.length === classCount ? (
                            <ClassDetails
                                isStudent={
                                    isStudent(selectedClass || selectClass) ||
                                    false
                                }
                                handleToggleModulePage={setViewModuleDetails}
                                handleToggleAddModule={handleToggleAddModule}
                                toggleGradeBookModal={handleToggleGradeBook}
                                classData={
                                    classes?.filter(
                                        (module) =>
                                            module.classID ===
                                            viewModuleDetails,
                                    )[0]
                                }
                                questions={questions}
                                modules={modulesForSelectedClass}
                                setClassStatus={toggleClassStatus}
                                setPages={setPages}
                                pages={pages}
                                setCurrentPage={setNewCurrentPage}
                                getModulePages={getModulePages}
                                disableForward={disableForward}
                                setDisableForward={setDisableForward}
                                selectedClassID={selectedClass || selectClass}
                                toggleBack={() => {
                                    setViewModuleDetails(0);
                                    setSelectedClass(undefined);
                                    if (classSelected) {
                                        classSelected(undefined);
                                    }
                                }}
                                showStudentGradebook={
                                    toggleShowStudentGradebookModal
                                }
                                refreshModules={() => {
                                    if (selectedClass) {
                                        getClassModules(
                                            selectedClass || selectClass || 0,
                                        );
                                    }
                                }}
                                orgList={organizations}
                                getClasses={getClasses}
                                setViewModuleDetails={setViewModuleDetails}
                                selectedModule={selectedModule || viewModuleId}
                                loading={loading}
                            />
                        ) : (
                            <></>
                        )}
                    </>
                )}
            </Card>
        </Box>
    );
};

export { Classes };
