import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { defaultTheme } from '../utilities/Utilites';
import Box from '@mui/system/Box';
import { Card, Grid } from '@mui/material';
import SliderSwitch from '../utilities/SliderSwitch';
import { useGlobalStore } from '../utilities/GlobalState';
import { GlobalState } from '../interfaces/Interfaces';
import { AuthPost } from '../utilities/Routing/Requests';
import { toast } from 'react-toastify';

export const Profile = () => {
    const globalState: any = useGlobalStore((state: GlobalState) => state);

    const [emailPreference, setEmailPreference] = React.useState(
        globalState?.user?.emailOptIn,
    );
    const submit = () => {
        return AuthPost(
            '/user/emailpreference',
            {
                id: globalState?.user?.id,
                preference: !emailPreference,
            },
            {
                'x-access-token': globalState.authToken,
                canCache: true,
            },
        )
            .then((res) => {
                if (res?.status === 201) {
                    const newUser = {
                        ...globalState?.user,
                    };
                    newUser.emailOptIn = !emailPreference;
                    globalState?.setUser(newUser);
                    setEmailPreference(!emailPreference);
                    return res.data;
                } else {
                    return [];
                }
            })
            .catch((err) => {
                toast.error(err.toString());
            });
    };

    return (
        <ThemeProvider theme={defaultTheme}>
            <Box
                sx={{
                    paddingTop: '30px',
                    margin: '10px 10px 10px 10px',
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <Card
                    sx={{
                        maxWidth: '100%',
                        padding: '20px',
                        textAlign: 'center',
                    }}
                >
                    <h2>{'Profile'}</h2>
                    <div className="user-agent-info">
                        <Grid container sx={{ margin: 0 }} spacing={2}>
                            {/* <Grid
                                sx={{
                                    padding: '16px',
                                    margin: 'auto',
                                }}
                                xs={6}
                                md={6}
                            >
                                <div className="info-card"></div>
                            </Grid> */}
                            <Grid
                                sx={{ padding: '16px', margin: 'auto' }}
                                md={12}
                                xs={12}
                            >
                                <div className="info-card">
                                    Receive Emails
                                    <div
                                        style={{
                                            fontWeight: 'lighter',
                                            fontSize: '14px',
                                        }}
                                    >
                                        No
                                        <SliderSwitch
                                            sx={{ m: 1 }}
                                            checked={emailPreference}
                                            onChange={submit}
                                        />
                                        Yes
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </Card>
            </Box>
        </ThemeProvider>
    );
};
