import * as React from 'react';
import { Module, ModulePage } from '../interfaces/Interfaces';
import {
    Button,
    Card,
    Grid,
    IconButton,
    TextField,
    Typography,
} from '@mui/material';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { ModulePageEditor } from './ModulePageEditor';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import AssignmentIcon from '@mui/icons-material/Assignment';
import { AuthPost } from '../utilities/Routing/Requests';
import { useGlobalStore } from '../utilities/GlobalState';
import { toast } from 'react-toastify';
import ImageUploader from './ImageUploader';
import { BlobServiceClient } from '@azure/storage-blob';
import { formatDateWithTime } from '../utilities/Utilites';
import { DateTime } from 'luxon';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ClearIcon from '@mui/icons-material/Clear';

interface Props {
    handleToggleModulePage: (moduleId: number) => void;
    handleToggleCreateModulePage: (
        edit?: boolean,
        newPageType?: string,
    ) => void;
    handleToggleCreateAssignmentPage: (
        edit?: boolean,
        newPageType?: string,
    ) => void;
    module: Module;
    pages: Array<ModulePage>;
    getModules: () => Promise<Array<Module>>;
    getModulePages: (moduleId: number) => Promise<Array<ModulePage>>;
    setPages: (pages: Array<ModulePage>) => void;
    toggleEditPage: (page: ModulePage) => void;
    toggleDeletePageModal: (id: number, refresh?: boolean) => void;
    toggleDeleteModuleModal: (id: number) => void;
}

interface ModuleBody {
    id: number;
    title: string;
    description: string;
    image: string;
    code: string;
    summary: string;
    duration: string;
    concepts: string;
}

const ModuleDetails = ({
    handleToggleModulePage,
    handleToggleCreateModulePage,
    handleToggleCreateAssignmentPage,
    module,
    pages,
    setPages,
    getModules,
    getModulePages,
    toggleEditPage,
    toggleDeletePageModal,
}: Props) => {
    const globalState = useGlobalStore((state) => state);

    const [editting, setEditting] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [title, setTitle] = React.useState(module?.title || '');
    const [description, setDescription] = React.useState(
        module?.description || '',
    );
    const [code, setCode] = React.useState(module?.code || '');
    const [summary, setSummary] = React.useState(module?.summary || '');
    const [instructorNotes, setInstructorNotes] = React.useState(
        module?.instructorNotes || '',
    );
    const [duration, setDuration] = React.useState(module?.duration || '');
    const [concepts, setConcepts] = React.useState(module?.concepts || '');
    const [certifications, setCertifications] = React.useState<Array<string>>(
        module?.certifications?.length
            ? JSON.parse(module?.certifications)
            : [''],
    );
    const [certificationsDetails, setCertificationsDetails] = React.useState<
        Array<string>
    >(
        module?.certificationsDetails?.length
            ? JSON.parse(module?.certificationsDetails)
            : [''],
    );
    const [outcomes, setOutcomes] = React.useState<Array<string>>(
        module?.outcomes?.length ? JSON.parse(module?.outcomes) : [''],
    );
    const [outcomesDetails, setOutcomesDetails] = React.useState<Array<string>>(
        module?.outcomesDetails?.length
            ? JSON.parse(module?.outcomesDetails)
            : [''],
    );
    const [equipments, setEquipments] = React.useState<Array<string>>(
        module?.equipments?.length ? JSON.parse(module?.equipments) : [''],
    );
    const [equipmentsDetails, setEquipmentsDetails] = React.useState<
        Array<string>
    >(
        module?.equipmentsDetails?.length
            ? JSON.parse(module?.equipmentsDetails)
            : [''],
    );
    const [file, setFile] = React.useState<Blob | null>(null);

    let localImageUrl = module?.image;
    const changeImage = (newImage: string | null) => {
        localImageUrl = newImage;
    };
    const changeTitle = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setTitle(e?.currentTarget?.value);
    };

    const changeDescription = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setDescription(e?.currentTarget?.value);
    };

    const changeCode = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setCode(e?.currentTarget?.value);
    };

    const changeSummary = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setSummary(e?.currentTarget?.value);
    };

    const changeInstructorNotes = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setInstructorNotes(e?.currentTarget?.value);
    };

    const changeDuration = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setDuration(e?.currentTarget?.value);
    };

    const changeConcepts = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setConcepts(e?.currentTarget?.value);
    };

    const toggleEditting = (refresh?: boolean) => {
        const current = editting;
        setEditting(!current);
        if (refresh) {
            getModules();
        }
    };

    //Storage account credentials
    const account = globalState?.env?.account; // get the storage account name from the .env file
    const sasToken = globalState?.env?.sasToken; // get the SAS token from the .env file
    const containerName = globalState?.env?.containerName || ''; // get the container name from the .env file
    const blobServiceClient = new BlobServiceClient(
        `https://${account}.blob.core.windows.net/?${sasToken}`,
    ); // create a blobServiceClient
    const containerClient = blobServiceClient.getContainerClient(containerName); // create a containerClient

    const blobToFile = (blob: Blob) =>
        new File([blob], 'create', {
            type: 'image/png',
            lastModified: Date.now(),
        });

    const handleSubmitImage = (fileForSubmit: Blob | null): Promise<string> => {
        if (fileForSubmit) {
            const fileToSubmit = blobToFile(fileForSubmit);
            const blobName = `${new Date().getTime()}-${
                fileToSubmit?.name || 'update.png'
            }`; // Specify a default blob name if needed
            if (!fileToSubmit) {
                // check if the file is selected
                // alert('Please select an image to upload');
                return new Promise((res) => res(''));
            }
            if (!account || !sasToken || !containerName) {
                // check if the credentials are set
                alert(
                    'Please make sure you have set the Azure Storage credentials in the .env file or as ENV Variable',
                );
                return new Promise((res) => res(''));
            }
            try {
                const blobClient = containerClient.getBlockBlobClient(blobName); // get the blob client
                return blobClient
                    .uploadData(fileToSubmit, {
                        blobHTTPHeaders: {
                            blobContentType: fileToSubmit?.type,
                        },
                    })
                    .then(() => {
                        return `https://imageslms.blob.core.windows.net/images-lms/${blobName}`;
                    });
            } catch (error: any) {
                console.error(error); // Handle error
                toast.error(error?.toString());
            }
            return new Promise((resolve) =>
                resolve(
                    `https://imageslms.blob.core.windows.net/images-lms/${blobName}`,
                ),
            );
        } else {
            return new Promise((res) => res(''));
        }
    };

    const toggleModuleActive = () => {
        const currentState = module.active || false;
        return AuthPost(
            '/organization/updatemoduleactive',
            { id: module.id, active: !currentState },
            {
                'x-access-token': globalState.authToken,
                canCache: false,
            },
        )
            .then((resp) => {
                if (resp?.status === 200) {
                    getModules();
                    return resp?.data;
                } else {
                    return [];
                }
            })
            .catch((err) => {
                toast.error(err.toString());
            });
    };

    const updateModule = async (updateBody?: ModuleBody) => {
        let data: Array<any> = [];
        if (file) {
            handleSubmitImage(file).then(async (res) => {
                await AuthPost(
                    '/organization/updatemodule',
                    updateBody
                        ? updateBody
                        : {
                              id: module?.id,
                              title: title,
                              description: description,
                              image: res,
                              code: code,
                              summary: summary,
                              duration: duration,
                              concepts: concepts,
                              instructorNotes: instructorNotes,
                              certifications: JSON.stringify(certifications),
                              certificationsDetails: JSON.stringify(
                                  certificationsDetails,
                              ),
                              outcomes: JSON.stringify(outcomes),
                              outcomesDetails: JSON.stringify(outcomesDetails),
                              equipments: JSON.stringify(equipments),
                              equipmentsDetails:
                                  JSON.stringify(equipmentsDetails),
                          },
                    {
                        'x-access-token': globalState.authToken,
                        canCache: false,
                    },
                )
                    .then((resp) => {
                        if (resp?.status === 200) {
                            data = resp?.data;
                            return resp?.data;
                        } else {
                            return [];
                        }
                    })
                    .catch((err) => {
                        toast.error(err.toString());
                    });
                toggleEditting(true);
                return data;
            });
        } else {
            await AuthPost(
                '/organization/updatemodule',
                updateBody
                    ? updateBody
                    : {
                          id: module?.id,
                          title: title,
                          description: description,
                          image: localImageUrl,
                          code: code,
                          summary: summary,
                          duration: duration,
                          concepts: concepts,
                          instructorNotes: instructorNotes,
                          certifications: JSON.stringify(certifications),
                          certificationsDetails: JSON.stringify(
                              certificationsDetails,
                          ),
                          outcomes: JSON.stringify(outcomes),
                          outcomesDetails: JSON.stringify(outcomesDetails),
                          equipments: JSON.stringify(equipments),
                          equipmentsDetails: JSON.stringify(equipmentsDetails),
                      },
                {
                    'x-access-token': globalState.authToken,
                    canCache: false,
                },
            )
                .then((resp) => {
                    if (resp?.status === 200) {
                        data = resp?.data;
                        return resp?.data;
                    } else {
                        return [];
                    }
                })
                .catch((err) => {
                    toast.error(err.toString());
                });
            toggleEditting(true);
            return data;
        }
    };

    const addCertificationInput = async () => {
        await setLoading(true);
        const modCerts: any = certifications;
        modCerts?.push('');
        await setCertifications(modCerts);

        const modCertsDeetz: any = certificationsDetails;
        modCertsDeetz?.push('');
        await setCertificationsDetails(modCertsDeetz);
        setLoading(false);
    };

    const onCertificationsInputChange = async (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        index: number,
    ) => {
        const originalCerts = certifications;
        let newCert = certifications?.[index];
        newCert = e.target.value;
        const newCerts = originalCerts.map((c: string, i: number) => {
            if (i !== index) {
                return c;
            } else {
                return newCert;
            }
        });
        await setCertifications(newCerts);
    };

    const onCertificationsDetailsInputChange = async (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        index: number,
    ) => {
        const originalCerts = certificationsDetails;
        let newCert = certificationsDetails[index];
        newCert = e.target.value;
        const newCerts = originalCerts.map((c: string, i: number) => {
            if (i !== index) {
                return c;
            } else {
                return newCert;
            }
        });
        await setCertificationsDetails(newCerts);
    };

    const onCertificationRemove = async (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        index: number,
    ) => {
        setLoading(true);
        const newCert = certifications.filter((q, i) => {
            return i !== index;
        });
        const newCertDeet = certificationsDetails.filter((q, i) => {
            return i !== index;
        });
        await setCertifications(newCert);
        await setCertificationsDetails(newCertDeet);
        setLoading(false);
    };

    const removeCertification = (ind: number) => {
        return (
            <IconButton
                onClick={(e: any) => {
                    onCertificationRemove(e, ind);
                }}
            >
                <ClearIcon />
            </IconButton>
        );
    };

    const mapCertifications = (mapCerts: any) => {
        return (
            <Card sx={{ marginBottom: '16px' }}>
                <Grid container>
                    <Grid xs={1}>
                        <IconButton
                            key={'add-answers-iconbutton'}
                            onClick={addCertificationInput}
                        >
                            <AddCircleIcon
                                sx={{
                                    width: '50px',
                                    height: '50px',
                                    color: 'green',
                                }}
                            />
                        </IconButton>
                    </Grid>
                    <Grid xs={11} sx={{ alignContent: 'center' }}>
                        <Typography>{'Add Certification'}</Typography>
                    </Grid>
                </Grid>
                {mapCerts?.map((cert: any, i: number) => {
                    return (
                        <>
                            <Grid
                                container
                                sx={{
                                    padding: '10px',
                                }}
                            >
                                <Grid xs={1} sx={{ alignContent: 'center' }}>
                                    <Typography
                                        sx={{
                                            verticalAlign: 'middle',
                                            textAlign: 'center',
                                        }}
                                    >
                                        {i + 1 + ') '}
                                    </Typography>
                                </Grid>
                                <Grid xs={11}>
                                    <div
                                        style={{
                                            padding: '10px',
                                        }}
                                    >
                                        <TextField
                                            fullWidth
                                            name="certifications"
                                            label="Certification"
                                            type="text"
                                            defaultValue={cert}
                                            onChange={(e) =>
                                                onCertificationsInputChange(
                                                    e,
                                                    i,
                                                )
                                            }
                                            InputProps={{
                                                endAdornment:
                                                    removeCertification(i),
                                            }}
                                        />
                                    </div>
                                </Grid>
                                <Grid xs={12}>
                                    <div style={{ padding: '10px' }}>
                                        <TextField
                                            fullWidth
                                            defaultValue={
                                                certificationsDetails[i]
                                            }
                                            name="certificationsDetails"
                                            label="Certification Detail"
                                            type="text"
                                            onChange={(e) =>
                                                onCertificationsDetailsInputChange(
                                                    e,
                                                    i,
                                                )
                                            }
                                        />
                                    </div>
                                </Grid>
                            </Grid>
                        </>
                    );
                })}
            </Card>
        );
    };

    const addOutcomeInput = async () => {
        await setLoading(true);
        const modOutcomes: any = outcomes;
        modOutcomes?.push('');
        await setOutcomes(modOutcomes);

        const modCertsDeetz: any = outcomesDetails;
        modCertsDeetz?.push('');
        await setOutcomesDetails(modCertsDeetz);
        setLoading(false);
    };

    const onOutcomesInputChange = async (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        index: number,
    ) => {
        const originalOutcomes = outcomes;
        let newOutcome = outcomes?.[index];
        newOutcome = e.target.value;
        const newOutcomes = originalOutcomes.map((c: string, i: number) => {
            if (i !== index) {
                return c;
            } else {
                return newOutcome;
            }
        });
        await setOutcomes(newOutcomes);
    };

    const onOutcomesDetailsInputChange = async (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        index: number,
    ) => {
        const originalOutcomes = outcomesDetails;
        let newCert = outcomesDetails[index];
        newCert = e.target.value;
        const newCerts = originalOutcomes.map((c: string, i: number) => {
            if (i !== index) {
                return c;
            } else {
                return newCert;
            }
        });
        await setOutcomesDetails(newCerts);
    };

    const onOutcomeRemove = async (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        index: number,
    ) => {
        setLoading(true);
        const newOutcome = outcomes.filter((q, i) => {
            return i !== index;
        });
        const newOutcomeDeet = outcomesDetails.filter((q, i) => {
            return i !== index;
        });
        await setOutcomes(newOutcome);
        await setOutcomesDetails(newOutcomeDeet);
        setLoading(false);
    };

    const removeOutcome = (ind: number) => {
        return (
            <IconButton
                onClick={(e: any) => {
                    onOutcomeRemove(e, ind);
                }}
            >
                <ClearIcon />
            </IconButton>
        );
    };

    const mapOutcomes = (outcomesToMap: any) => {
        return (
            <Card sx={{ marginBottom: '16px' }}>
                <Grid container>
                    <Grid xs={1}>
                        <IconButton
                            key={'add-answers-iconbutton'}
                            onClick={addOutcomeInput}
                        >
                            <AddCircleIcon
                                sx={{
                                    width: '50px',
                                    height: '50px',
                                    color: 'green',
                                }}
                            />
                        </IconButton>
                    </Grid>
                    <Grid xs={11} sx={{ alignContent: 'center' }}>
                        <Typography>{'Add Outcome'}</Typography>
                    </Grid>
                </Grid>
                {outcomesToMap?.map((outs: any, i: number) => {
                    return (
                        <>
                            <Grid
                                container
                                sx={{
                                    padding: '10px',
                                }}
                            >
                                <Grid xs={1} sx={{ alignContent: 'center' }}>
                                    <Typography
                                        sx={{
                                            verticalAlign: 'middle',
                                            textAlign: 'center',
                                        }}
                                    >
                                        {i + 1 + ') '}
                                    </Typography>
                                </Grid>
                                <Grid xs={11}>
                                    <div
                                        style={{
                                            padding: '10px',
                                        }}
                                    >
                                        <TextField
                                            fullWidth
                                            name="outcomes"
                                            label="Outcome"
                                            type="text"
                                            defaultValue={outs}
                                            onChange={(e) =>
                                                onOutcomesInputChange(e, i)
                                            }
                                            InputProps={{
                                                endAdornment: removeOutcome(i),
                                            }}
                                        />
                                    </div>
                                </Grid>
                                <Grid xs={12}>
                                    <div style={{ padding: '10px' }}>
                                        <TextField
                                            fullWidth
                                            defaultValue={outcomesDetails[i]}
                                            name="outcomesDetails"
                                            label="Outcome Detail"
                                            type="text"
                                            onChange={(e) =>
                                                onOutcomesDetailsInputChange(
                                                    e,
                                                    i,
                                                )
                                            }
                                        />
                                    </div>
                                </Grid>
                            </Grid>
                        </>
                    );
                })}
            </Card>
        );
    };

    const addEquipmentInput = async () => {
        await setLoading(true);
        const modEquipments: any = equipments;
        modEquipments?.push('');
        await setEquipments(modEquipments);

        const modCertsDeetz: any = equipmentsDetails;
        modCertsDeetz?.push('');
        await setEquipmentsDetails(modCertsDeetz);
        setLoading(false);
    };

    const onEquipmentsInputChange = async (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        index: number,
    ) => {
        const originalEquipments = equipments;
        let newEquipment = equipments?.[index];
        newEquipment = e.target.value;
        const newEquipments = originalEquipments.map((c: string, i: number) => {
            if (i !== index) {
                return c;
            } else {
                return newEquipment;
            }
        });
        await setEquipments(newEquipments);
    };

    const onEquipmentsDetailsInputChange = async (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        index: number,
    ) => {
        const originalEquipments = equipmentsDetails;
        let newCert = equipmentsDetails[index];
        newCert = e.target.value;
        const newCerts = originalEquipments.map((c: string, i: number) => {
            if (i !== index) {
                return c;
            } else {
                return newCert;
            }
        });
        await setEquipmentsDetails(newCerts);
    };

    const onEquipmentRemove = async (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        index: number,
    ) => {
        setLoading(true);
        const newEquipment = equipments.filter((q, i) => {
            return i !== index;
        });
        const newEquipmentDeet = equipmentsDetails.filter((q, i) => {
            return i !== index;
        });
        await setEquipments(newEquipment);
        await setEquipmentsDetails(newEquipmentDeet);
        setLoading(false);
    };

    const removeEquipment = (ind: number) => {
        return (
            <IconButton
                onClick={(e: any) => {
                    onEquipmentRemove(e, ind);
                }}
            >
                <ClearIcon />
            </IconButton>
        );
    };

    const mapEquipments = (equipmentsToMap: any) => {
        return (
            <Card sx={{ marginBottom: '16px' }}>
                <Grid container>
                    <Grid xs={1}>
                        <IconButton
                            key={'add-answers-iconbutton'}
                            onClick={addEquipmentInput}
                        >
                            <AddCircleIcon
                                sx={{
                                    width: '50px',
                                    height: '50px',
                                    color: 'green',
                                }}
                            />
                        </IconButton>
                    </Grid>
                    <Grid xs={11} sx={{ alignContent: 'center' }}>
                        <Typography>{'Add Equipment'}</Typography>
                    </Grid>
                </Grid>
                {equipmentsToMap?.map((outs: any, i: number) => {
                    return (
                        <>
                            <Grid
                                container
                                sx={{
                                    padding: '10px',
                                }}
                            >
                                <Grid xs={1} sx={{ alignContent: 'center' }}>
                                    <Typography
                                        sx={{
                                            verticalAlign: 'middle',
                                            textAlign: 'center',
                                        }}
                                    >
                                        {i + 1 + ') '}
                                    </Typography>
                                </Grid>
                                <Grid xs={11}>
                                    <div
                                        style={{
                                            padding: '10px',
                                        }}
                                    >
                                        <TextField
                                            fullWidth
                                            name="equipments"
                                            label="Equipment"
                                            type="text"
                                            defaultValue={outs}
                                            onChange={(e) =>
                                                onEquipmentsInputChange(e, i)
                                            }
                                            InputProps={{
                                                endAdornment:
                                                    removeEquipment(i),
                                            }}
                                        />
                                    </div>
                                </Grid>
                                <Grid xs={12}>
                                    <div style={{ padding: '10px' }}>
                                        <TextField
                                            fullWidth
                                            defaultValue={equipmentsDetails[i]}
                                            name="equipmentsDetails"
                                            label="Equipment Detail"
                                            type="text"
                                            onChange={(e) =>
                                                onEquipmentsDetailsInputChange(
                                                    e,
                                                    i,
                                                )
                                            }
                                        />
                                    </div>
                                </Grid>
                            </Grid>
                        </>
                    );
                })}
            </Card>
        );
    };

    return (
        <div style={{ margin: '10px' }}>
            <div style={{ textAlign: 'right', paddingRight: '10px' }}>
                Published:{' '}
                {module?.publishDate
                    ? formatDateWithTime(DateTime.fromISO(module.publishDate))
                    : 'Unpublished'}
            </div>
            <Grid
                container
                style={{
                    padding: '10px 10px 10px 10px',
                    // backgroundColor: '#f44336',
                    // color: '#fff',
                    fontWeight: 'bold',
                    fontSize: '20px',
                }}
            >
                <Grid
                    xs={4}
                    sx={{
                        textAlignLast: 'left',
                    }}
                >
                    <IconButton onClick={() => handleToggleModulePage(0)}>
                        <KeyboardBackspaceIcon />
                    </IconButton>
                </Grid>
                <Grid xs={4} sx={{ alignSelf: 'center', textAlign: 'center' }}>
                    {!editting ? (
                        module?.title
                    ) : (
                        <TextField
                            required
                            fullWidth
                            id="title"
                            label="Title"
                            name="title"
                            defaultValue={module?.title}
                            onChange={changeTitle}
                        />
                    )}
                </Grid>
                {!editting ? (
                    <Grid
                        xs={4}
                        sx={{
                            textAlignLast: 'right',
                        }}
                    >
                        <IconButton
                            sx={{
                                marginLeft: '5px',
                                backgroundColor: 'green',
                                border: 'solid #fff 1px',
                                color: '#fff',
                                fontWeight: 'bold',
                                fontSize: '20px',
                            }}
                            title={'New Content'}
                            onClick={() =>
                                handleToggleCreateModulePage(false, 'text')
                            }
                        >
                            <NoteAddIcon />
                        </IconButton>
                        <IconButton
                            sx={{
                                marginLeft: '5px',
                                backgroundColor: 'green',
                                border: 'solid #fff 1px',
                                color: '#fff',
                                fontWeight: 'bold',
                                fontSize: '20px',
                            }}
                            title={'New Questions'}
                            onClick={() =>
                                handleToggleCreateAssignmentPage(
                                    false,
                                    'assignment',
                                )
                            }
                        >
                            <AssignmentIcon />
                        </IconButton>
                        <IconButton
                            sx={{
                                marginLeft: '5px',
                                backgroundColor: '#f1c40f',
                                border: 'solid #fff 1px',
                                color: '#fff',
                                fontWeight: 'bold',
                                fontSize: '20px',
                            }}
                            title={'Edit Module'}
                            onClick={() => toggleEditting()}
                        >
                            <SettingsApplicationsIcon />
                        </IconButton>
                        {/* <IconButton
                            sx={{
                                marginLeft: '5px',
                                backgroundColor: '#e74c3c',
                                border: 'solid #fff 1px',
                                color: '#fff',
                                fontWeight: 'bold',
                                fontSize: '20px',
                            }}
                            title={'Delete Module'}
                            onClick={() => {
                                toggleDeleteModuleModal(module?.id);
                            }}
                        >
                            <DeleteIcon />
                        </IconButton> */}
                        <Button
                            variant="contained"
                            sx={{
                                // mt: 3,
                                // mb: 2,
                                marginLeft: '5px',
                                backgroundColor: module.active
                                    ? '#f44336'
                                    : 'green',
                                '&:hover': {
                                    backgroundColor: 'rgba(0, 0, 0, 0.04)',
                                    color: 'black',
                                },
                            }}
                            onClick={toggleModuleActive}
                        >
                            {module?.active ? 'Unpublish' : 'Publish'}
                        </Button>
                    </Grid>
                ) : (
                    <Grid
                        xs={4}
                        sx={{
                            textAlignLast: 'right',
                        }}
                    >
                        <Button
                            variant="contained"
                            sx={{
                                mt: 3,
                                mb: 2,
                                backgroundColor: '#f44336',
                                '&:hover': {
                                    backgroundColor: 'rgba(0, 0, 0, 0.04)',
                                    color: 'black',
                                },
                            }}
                            onClick={() => updateModule()}
                        >
                            Save
                        </Button>
                    </Grid>
                )}
            </Grid>
            <br />
            {!editting ? (
                <>
                    <div style={{ textAlign: 'center' }}>
                        Description: {module?.description}
                    </div>
                    <div style={{ textAlign: 'center' }}>
                        Code: {module?.code}
                    </div>
                    <div style={{ textAlign: 'center' }}>
                        Summary: {module?.summary}
                    </div>
                    <div style={{ textAlign: 'center' }}>
                        Instructor Notes: {module?.instructorNotes}
                    </div>
                    <div style={{ textAlign: 'center' }}>
                        Duration: {module?.duration}
                    </div>
                    <div style={{ textAlign: 'center' }}>
                        Concepts: {module?.concepts}
                    </div>
                    <div style={{ textAlign: 'center' }}>
                        Certifications:{' '}
                        {module?.certifications?.length
                            ? JSON.parse(module?.certifications)?.map(
                                  (cert: any, i: number) => {
                                      return (
                                          <>
                                              <div
                                                  style={{
                                                      textAlign: 'center',
                                                  }}
                                              >
                                                  <Typography>
                                                      {i +
                                                          1 +
                                                          ') ' +
                                                          cert +
                                                          ': ' +
                                                          certificationsDetails?.[
                                                              i
                                                          ]}
                                                  </Typography>
                                              </div>
                                          </>
                                      );
                                  },
                              )
                            : ''}
                    </div>
                    <div style={{ textAlign: 'center' }}>
                        Equipment:{' '}
                        {module?.equipments?.length
                            ? JSON.parse(module?.equipments)?.map(
                                  (cert: any, i: number) => {
                                      return (
                                          <>
                                              <div
                                                  style={{
                                                      textAlign: 'center',
                                                  }}
                                              >
                                                  <Typography>
                                                      {i +
                                                          1 +
                                                          ') ' +
                                                          cert +
                                                          ': ' +
                                                          equipmentsDetails?.[
                                                              i
                                                          ]}
                                                  </Typography>
                                              </div>
                                          </>
                                      );
                                  },
                              )
                            : ''}
                    </div>
                    <div style={{ textAlign: 'center' }}>
                        Outcomes:{' '}
                        {module?.outcomes?.length
                            ? JSON.parse(module?.outcomes)?.map(
                                  (cert: any, i: number) => {
                                      return (
                                          <>
                                              <div
                                                  style={{
                                                      textAlign: 'center',
                                                  }}
                                              >
                                                  <Typography>
                                                      {i +
                                                          1 +
                                                          ') ' +
                                                          cert +
                                                          ': ' +
                                                          outcomesDetails?.[i]}
                                                  </Typography>
                                              </div>
                                          </>
                                      );
                                  },
                              )
                            : ''}
                    </div>
                    {module?.image?.length ? (
                        <div style={{ textAlign: 'center' }}>
                            <img
                                style={{ width: '50%' }}
                                src={module?.image || ''}
                                alt="Module Details Missing Image"
                            />
                        </div>
                    ) : (
                        <></>
                    )}
                </>
            ) : (
                <>
                    <TextField
                        required
                        id="description"
                        name="description"
                        label="Description"
                        fullWidth
                        multiline={true}
                        sx={{ marginBottom: '16px' }}
                        defaultValue={module?.description}
                        onChange={changeDescription}
                        minRows={3}
                        placeholder="Description *"
                        value={description}
                    />
                    <TextField
                        required
                        fullWidth
                        id="code"
                        label="Code"
                        name="code"
                        defaultValue={module?.code}
                        sx={{ marginBottom: '16px' }}
                        onChange={changeCode}
                    />
                    <TextField
                        minRows={3}
                        fullWidth
                        placeholder="Summary *"
                        label="Summary"
                        multiline={true}
                        sx={{ marginBottom: '16px' }}
                        required
                        id="summary"
                        name="summary"
                        value={summary}
                        onChange={changeSummary}
                    />
                    <TextField
                        minRows={3}
                        fullWidth
                        placeholder="Instructor Notes"
                        label="Instructor Notes"
                        multiline={true}
                        sx={{ marginBottom: '16px' }}
                        id="instructorNotes"
                        name="instructorNotes"
                        value={instructorNotes}
                        onChange={changeInstructorNotes}
                    />
                    <TextField
                        required
                        fullWidth
                        id="concepts"
                        label="Concepts"
                        name="concepts"
                        defaultValue={module?.concepts}
                        sx={{ marginBottom: '16px' }}
                        onChange={changeConcepts}
                    />
                    <TextField
                        required
                        fullWidth
                        id="duration"
                        label="Duration"
                        name="duration"
                        defaultValue={module?.duration}
                        sx={{ marginBottom: '16px' }}
                        onChange={changeDuration}
                    />
                    {!loading && mapCertifications(certifications)}
                    {!loading && mapOutcomes(outcomes)}
                    {!loading && mapEquipments(equipments)}
                    <ImageUploader
                        setFile={(newFile) => setFile(newFile)}
                        imageUrl={localImageUrl}
                        file={file}
                        changeUrl={changeImage}
                        deleteCallback={() =>
                            updateModule({
                                id: module?.id,
                                title: title,
                                description: description,
                                image: '',
                                code: code,
                                summary: summary,
                                duration: duration,
                                concepts: concepts,
                            })
                        }
                    />
                </>
            )}

            <br />
            <ModulePageEditor
                handleToggleCreateModulePage={handleToggleCreateModulePage}
                module={module}
                pages={pages}
                getModulePages={getModulePages}
                setPages={setPages}
                toggleEditPage={toggleEditPage}
                toggleDeletePageModal={toggleDeletePageModal}
            />
        </div>
    );
};

export { ModuleDetails };
